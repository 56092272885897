import { useAlert } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import {
  ADD_USER_IN_COURSE,
  DELETE_COURSE,
  SEARCH_USER_ON_DB,
  SET_COURSES,
  SET_COURSE_DETAIL,
  SET_COURSE_USERS,
  SET_CURRENT_COURSE,
  TAG_COURSE_USER,
  TOGGLE_COURSE_STATUS,
  SET_CURRENT_COURSEFILES,
} from 'stores/slices/CourseSlice'
import axios from 'utils/axios'
import htmlToReact from 'utils/htmlToReact'
import fs from 'fs'

const useCourse = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()
  // tab
  const courses = useSelector(state => state.course.courses)
  const coursesLength = useSelector(state => state.course.coursesLength)
  const courseDetail = useSelector(state => state.course.courseDetail)
  const courseDelete = useSelector(state => state.course.courseDelete)
  const courseUsers = useSelector(state => state.course.courseUsers)
  const courseUsersLength = useSelector(state => state.course.courseUsersLength)
  const tagCourseUser = useSelector(state => state.course.tagCourseUser)
  const searchedUser = useSelector(state => state.course.searchedUser)
  const currentCourse = useSelector(state => state.course.currentCourse)
  const currentCourseFiles = useSelector(state => state.course.currentCourseFiles)

  // handle

  const handleGetCourses = async (page = 1) => {
    try {
      const response = await axios.get(`${GET_API().courses}?page=${page}`)
      console.log('handleGetCourses', response)

      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          courses: response.data.courses.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }
        dispatch(SET_COURSES(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetMembersOfCourse = async (courseID, page = 1, tag, mode) => {
    try {
      let response
      if (mode === 'all') {
        response = await axios.get(`${GET_API(courseID).courseUser}?mode=all`)

        return response.data.users
      }

      response = await axios.get(`${GET_API(courseID).courseUser}?page=${page}`)

      if (response.data.status === 'success') {
        const newCourseUser = {
          totalLength: response.data.totalLength,
          courseUsers: response.data.users.map(({ _id, ...item }) => ({
            ...item,
            id: _id,
            time: Math.floor(Math.random() * 16),
          })),
          tag,
        }
        return dispatch(SET_COURSE_USERS(newCourseUser))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleGetRegisteredUsers = async (courseID, page = 1, tag) => {
    try {
      const response = await axios.get(`${GET_API(courseID).courseRegister}?page=${page}`)
      console.log('response tag:', tag, response)

      if (response.data.status === 'success') {
        const newCourseUser = {
          totalLength: response.data.totalLength,
          courseUsers: response.data.users.map(({ _id, ...item }) => ({
            ...item,
            id: _id,
            time: Math.floor(Math.random() * 16),
          })),
          tag,
        }
        return dispatch(SET_COURSE_USERS(newCourseUser))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetCourseByID = async (id, isSavePackage = false) => {
    dispatch(SEARCH_USER_ON_DB([]))
    try {
      const response = await axios.get(`${GET_API(id).courseByID}`)
      if (response.data.status === 'success') {
        const newCourse = {
          ...response.data.course,
          certificateConditions: htmlToReact(response.data.course.certificateConditions),
          studentsBenefits: htmlToReact(response.data.course.studentsBenefits),
        }
        if (isSavePackage) {
          return dispatch(SET_COURSE_DETAIL(newCourse))
        }
        return newCourse
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleUploadFilesForCourse = async (courseId, formData) => {
    try {
      console.log('formData', formData)
      const response = await axios.post(POST_API(courseId).uploadCourseFiles, formData)
      if (response.data.status === 'success') {
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleDeleteFiles = async (courseId, id) => {
    try {
      const response = await axios.delete(DELETE_API(id).deleteCourseFiles, {
        courseId,
      })
      if (response.data.status === 'success') {
        enqueueSnackbar('Đã xóa file', { variant: 'success' })

        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleAddCourse = async (form, files) => {
    try {
      const response = await axios.post(POST_API().course, form)

      if (response.data.status === 'success') {
        await handleUploadFilesForCourse(response.data.course._id, files)
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditCourse = async (id, form, files) => {
    try {
      const formData = new FormData()

      if (files) {
        files.forEach(file => {
          formData.append('files', file.file)
        })

        const res = await handleUploadFilesForCourse(id, formData)
      }

      const response = await axios.patch(UPDATE_API(id).course, form)
      if (response.data.status === 'success') {
        // enqueueSnackbar('Cập nhập khóa học thành công', { variant: 'success' })
        return true
      }
      enqueueSnackbar('Không cập nhập được khóa học', { variant: 'error' })
      return false
    } catch (error) {
      console.log(error)
    }
  }
  const handelResetSearchedUsers = () => {
    dispatch(SEARCH_USER_ON_DB([]))
  }
  const handleAddNewUserOnCourse = async (selectedUsers, courseId, tag) => {
    try {
      const userIDs = selectedUsers.map(user => user.id)
      const response = await axios.post(POST_API(courseId).saveCourseMembers, {
        courseMembers: userIDs,
        method: 'add',
      })
      if (response.data.status === 'success') {
        // await handleGetMembersOfCourse(courseId, 1, 0)
        dispatch(TAG_COURSE_USER(0))
        dispatch(ADD_USER_IN_COURSE({ selectedUsers, tag }))
        enqueueSnackbar('Thêm học viên mới thành công', { variant: 'success' })
      } else {
        enqueueSnackbar(response.data.message, { variant: 'warning' })
      }
    } catch (error) {
      enqueueSnackbar('Đã có lỗi xảy ra. Vui lòng thử lại sau.', { variant: 'warning' })
    }
  }

  const handleSearchUserOnDB = async (form, currentPage) => {
    try {
      const values = Object.values(form)
      if (values.every(value => value === '')) {
        dispatch(SEARCH_USER_ON_DB([]))
        return
      }
      const response = await axios.post(`${POST_API().searchLawyers}?page=${currentPage}`, form)

      if (response.data.status === 'success') {
        const newMember = response.data.result
        dispatch(SEARCH_USER_ON_DB(newMember))
        return response.data.result
      }
      enqueueSnackbar('Đã xảy ra lỗi', { variant: 'error' })

      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleDeleteCourse = async (course = null, isAccept) => {
    if (!isAccept) return dispatch(DELETE_COURSE(course))
    try {
      const response = await axios.delete(DELETE_API(courseDelete).course)
      console.log('handleDeleteUser', response)

      if (response.data.status === 'success') {
        const newCourses = courses.filter(course => course?.id !== courseDelete)

        dispatch(SET_COURSES({ courses: newCourses }))

        enqueueSnackbar('Xóa khóa học thành công', { variant: 'success' })
        dispatch(DELETE_COURSE(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleSaveCourseMembers = async (courseID, listMembers, method = 'add') => {
    try {
      const response = await axios.post(POST_API(courseID).saveCourseMembers, {
        courseMembers: listMembers,
        method,
      })

      if (response.data.status === 'success') {
        enqueueSnackbar(response.data.message, { variant: 'success' })
        const newCourseUsers = courseUsers.filter(i => !listMembers.some(s => s === i.id))
        return dispatch(SET_COURSE_USERS({ courseUsers: newCourseUsers }))
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleRemoveCourseMembers = async memberID => {
    try {
      const listMembers = courseUsers[tagCourseUser].filter(item => item.id === memberID).map(item => item.id)
      const response = await axios.post(POST_API(currentCourse).saveCourseMembers, {
        courseMembers: listMembers,
        method: 'remove',
      })
      if (response.data.status === 'success') {
        enqueueSnackbar('Xóa học viên thành công', { variant: 'success' })
        const restUsersOnCourse = courseUsers[tagCourseUser].filter(i => !listMembers.some(s => s === i.id))
        const newCourseUser = {
          totalLength: courseUsersLength[tagCourseUser] - restUsersOnCourse.length,
          courseUsers: restUsersOnCourse,
          tag: tagCourseUser,
        }
        console.log(newCourseUser)
        return dispatch(SET_COURSE_USERS(newCourseUser))
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleUpdateStatusCourse = async (courseID, status) => {
    try {
      const response = await axios.patch(UPDATE_API(courseID).course, { status })
      console.log('handleEditCourse', status, response)
      if (response.data.status === 'success') {
        dispatch(TOGGLE_COURSE_STATUS({ id: response.data.course._id, status: response.data.course.status }))
        enqueueSnackbar(`${response.data.course.status === 0 ? 'Đã mở khóa học' : 'Đã đóng khóa học'}`, {
          variant: 'success',
        })
        return true
      }
      enqueueSnackbar('Đã xảy ra lỗi', { variant: 'error' })

      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleSetCurrentCourse = async currentCourse => {
    await dispatch(SET_CURRENT_COURSE(currentCourse))
  }

  const handleSetCurrentCourseFiles = async files => {
    await dispatch(SET_CURRENT_COURSEFILES(files))
  }

  // files

  const handleChangeTagCourseUser = tag => dispatch(TAG_COURSE_USER(tag))
  return {
    courses,
    courseDelete,
    courseDetail,
    coursesLength,
    searchedUser,
    courseUsers,
    courseUsersLength,
    tagCourseUser,
    currentCourse,
    currentCourseFiles,
    handleDeleteFiles,
    handleUploadFilesForCourse,
    handleSetCurrentCourse,
    handleGetRegisteredUsers,
    handleSearchUserOnDB,
    handelResetSearchedUsers,
    handleAddNewUserOnCourse,
    handleGetMembersOfCourse,
    handleGetCourses,
    handleGetCourseByID,
    handleAddCourse,
    handleDeleteCourse,
    handleEditCourse,
    handleSaveCourseMembers,
    handleRemoveCourseMembers,
    handleUpdateStatusCourse,
    handleChangeTagCourseUser,
    handleSetCurrentCourseFiles,
  }
}

export default useCourse
