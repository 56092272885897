import * as React from 'react'
import { ClickAwayListener, Typography, Grow, Paper, Popper, MenuItem, MenuList, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import { TAG_USER } from 'stores/slices/UserSlice'
import { useDispatch } from 'react-redux'

const MenuAuth = ({ open, anchor, onClose, handleLogout }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const role = JSON.parse(localStorage.getItem('lawyerRole'))
  return (
    <Box sx={{ position: 'relative' }}>
      {open ? (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            background: 'transparent',
            zIndex: 3,
          }}
        />
      ) : (
        ''
      )}
      <Popper
        open={open}
        anchorEl={anchor.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        style={{ zIndex: 4 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              zIndex: 4,
            }}
          >
            <Paper sx={{ zIndex: 4, width: 200 }}>
              <ClickAwayListener onClickAway={onClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
                  <MenuItem>
                    <Typography
                      component="p"
                      onClick={() => {
                        dispatch(TAG_USER(1))
                        navigate(`${PATH_PAGE.users.path}/admin`)
                        onClose()
                      }}
                    >
                      Quản lý admin
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
export default MenuAuth
