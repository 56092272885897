import React from 'react'
import { Avatar } from '@mui/material'

const MAvatar = props => {
  const { sx, alt } = props
  return (
    <Avatar {...props} alt={alt} sx={{ width: 38, height: 38, ...sx }}>
      {alt ? alt.slice(0, 1) : null}
    </Avatar>
  )
}

export default MAvatar
