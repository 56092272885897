const GET_API = id => {
  return {
    userById: `/lawyer/${id}`,
    lawUsers: `/lawyers`,
    users: '/normalusers',

    polls: `/polls`,
    pollByID: `/poll/${id}`,

    courses: `/courses`,
    coursesUser: `/userCourses`,
    courseByID: `/course/${id}`,
    // courseRegister: `/course/${id}/updateMembers`,
    courseUser: `/course/${id}/members`,
    courseRegister: `/course/${id}/registeredUsers`,
    getCourseFiles: `/course/${id}/coursefiles`,
    forums: `/posts`,
    forumByID: `/posts/${id}`,
    commentByPost: `/post/${id}/comments`,

    verifyForum: `/post/${id}/verify`,
    verifyComment: `/comments/${id}`,

    news: `/news`,
    newsById: `/news/${id}`,
    agendas: `/agendas`,
    agendasById: `/agenda/${id}`,

    fees: `/fees`,
    feeByID: `/fees/${id}`,
    searchFeeByLawyerCode: `/fees/lawyer`,
    analysis: `/users/yearlyRecords`,
    complains: `/complains`,

    /// ti nua check
    analysisLawyers: `/analysis/lawyers`,
    lawyersByProvince: `/analysis/lawyer`,
    analysisAdmin: `/analysis/admins`,
    lawLawyersByProvinceCode: `/analysisLawyers/searchLawyersByProvinceCode`,
    searchLawyers: `/searchLawyers`,
  }
}

const POST_API = id => {
  return {
    login: `/login`,
    registerLawyer: `/registerLawyer`,
    registerUser: `/register`,
    searchLawyers: `/lawyers/searchLawyers`,

    forgotPwd: `/confirmResetPassword`,
    resetPwd: `/resetPassword/${id}`,

    pollsNQ: `/nqPoll/create`,
    pollsMV: `/mvPoll/create`,

    course: `/course/create`,
    uploadCourseFiles: `/course/${id}/coursefiles`,

    // courseRegister: `/course/${id}/updateCourseMembers`,
    saveCourseMembers: `/course/${id}/updateCourseMembers`,

    forums: `/createPost`,
    comments: `/post/${id}/comments/create`,

    agenda: `/agenda/create`,
    news: `/news/create`,
    upNewsImge: `/news/uploadImage`,

    importAnalysis: `/user/importAnalysis`,
    importUser: `/user/import`,
    importLawyers: `/lawyers/import`,

    // chua dc
    lawLawyersByProvinceCode: `/searchLawyers`,
    //
    complains: `/complains`,
  }
}

const UPDATE_API = id => {
  return {
    poll: `/poll/${id}/edit`,
    pollStatus: `/poll/${id}/updateStatus`,

    course: `/course/${id}/edit`,
    userEdit: `/lawyer/${id}`,
    adminEditForUser: `/lawyer/${id}`,
    agenda: `/agenda/${id}/edit`,
    news: `/news/${id}/edit`,
    complain: `/complain/${id}`,
    fee: `/fee`,
    fees: `/fees`,
  }
}
const DELETE_API = (id, id2) => {
  return {
    poll: `/poll/${id}/delete`,
    user: `/lawyer/${id}`,
    course: `/course/${id}/delete`,
    deleteCourseFiles: `/coursefile/${id}/delete`,
    forums: `/posts/${id}/delete`,
    comments: `/post/${id}/comments/${id2}/delete`,
    agenda: `/agenda/${id}/delete`,
    news: `/news/${id}/delete`,
    complain: `/complain/${id}`,
    fee: `/fee/${id}`,
  }
}

export { GET_API, POST_API, UPDATE_API, DELETE_API }
