// routes
import { PATH_PAGE } from 'routes/paths'
import icons from 'constants/icons'
import subVn from 'pc-vn'

const sidebarConfig = [
  {
    ...PATH_PAGE.courses,
    icon: icons.LocalLibraryIcon,
  },
  {
    ...PATH_PAGE.fees,
    icon: icons.AttachMoneyIcon,
  },
  // {
  //   ...PATH_PAGE.protects,
  //   icon: icons.SecurityIcon,
  // },
  // {
  //   ...PATH_PAGE.boards,
  //   icon: icons.PostIcon,
  // },
  // {
  //   ...PATH_PAGE.HDLSTQ,
  //   icon: icons.PublicIcon,
  // },
  // {
  //   ...PATH_PAGE.TVLD,
  //   icon: icons.PollIcon,
  // },
  {
    ...PATH_PAGE.CDLS,
    icon: icons.GroupsIcon,
  },

  {
    ...PATH_PAGE.news,
    icon: icons.LocalLibraryIcon,
  },
  // {
  //   ...PATH_PAGE.forums,
  //   icon: icons.PostIcon,
  // },

  // {
  //   ...PATH_PAGE.users,
  //   icon: icons.PersonIcon,
  // },

  {
    ...PATH_PAGE.protects,
    icon: icons.GroupsIcon,
  },
]

// province
const provinces = () => {
  const getProvinces = subVn.getProvinces()

  const getUnitTp = getProvinces.filter(item => item.unit === 'Thành phố Trung ương')
  const getUnitTinh = getProvinces.filter(item => item.unit !== 'Thành phố Trung ương')

  const newTp = getUnitTp.sort((a, b) => {
    return `${a.name}`.localeCompare(b.name)
  })

  const newSort = [...newTp, ...getUnitTinh]
  return newSort.map((item, index) => ({ id: index + 1, label: item.name, value: item.code }))
}

const expertiseLawyers = [
  { label: 'Dân sự', value: 'Dân sự' },
  { label: 'Hình sự', value: 'Hình sự' },
  { label: 'Hành chính', value: 'Hành chính' },
  { label: 'Đầu tư', value: 'Đầu tư' },
  { label: 'Doanh nghiệp', value: 'Doanh nghiệp' },
  { label: 'Xây dựng', value: 'Xây dựng' },
  { label: 'Bất động sản', value: 'Bất động sản' },
  {
    label: 'Cơ sở hạ tầng và năng lượng',
    value: 'Cơ sở hạ tầng và năng lượng',
  },
  { label: 'Tài chính ngân hàng', value: 'Tài chính ngân hàng' },
  { label: 'Sở hữu trí tuệ', value: 'Sở hữu trí tuệ' },
  { label: 'Thuế và hải quan', value: 'Thuế và hải quan' },
  { label: 'Hôn nhân gia đình', value: 'Hôn nhân gia đình' },
  { label: 'Lao động', value: 'Lao động' },
  { label: 'Cạnh tranh', value: 'Cạnh tranh' },
  { label: 'Dự án đầu tư công', value: 'Dự án đầu tư công' },
]
const areaLawyers = [
  { label: 'Dân sự', value: 'Dân sự' },
  { label: 'Hình sự', value: 'Hình sự' },
  { label: 'Hành chính', value: 'Hành chính' },
  { label: 'Trọng tài', value: 'Trọng tài' },
  { label: 'Hòa giải', value: 'Hòa giải' },
  { label: 'Luật sư nội bộ công ty', value: 'Luật sư nội bộ công ty' },
  { label: 'Quản trị doanh nghiệp', value: 'Quản trị doanh nghiệp' },
  { label: 'Sản xuất', value: 'Sản xuất' },
  { label: 'Dịch vụ', value: 'Dịch vụ' },
  { label: 'Giáo dục, y tế', value: 'Giáo dục, y tế' },
]

const months = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 1,
    label: 'Tháng 1',
  },
  {
    value: 2,
    label: 'Tháng 2',
  },
  {
    value: 3,
    label: 'Tháng 3',
  },
  {
    value: 4,
    label: 'Tháng 4',
  },
  {
    value: 5,
    label: 'Tháng 5',
  },
  {
    value: 6,
    label: 'Tháng 6',
  },
  {
    value: 7,
    label: 'Tháng 7',
  },
  {
    value: 8,
    label: 'Tháng 8',
  },
  {
    value: 9,
    label: 'Tháng 9',
  },
  {
    value: 10,
    label: 'Tháng 10',
  },
  {
    value: 11,
    label: 'Tháng 11',
  },
  {
    value: 12,
    label: 'Tháng 12',
  },
]

const yearsCustom = () => {
  let i = 1975
  let arrs = []
  const yearCurrent = new Date().getFullYear()
  while (i <= yearCurrent) {
    arrs = [...arrs, { label: `${i}`, value: i }]
    i++
  }
  return arrs
}
const years = yearsCustom().reverse()

export { sidebarConfig, provinces, expertiseLawyers, areaLawyers, months, years }
