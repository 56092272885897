import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  fees: {
    data: [],
    total: {
      pages: {},
      feesOnPage: {},
    },
  },
  complains: {
    data: [],
    total: {
      pages: {},
      complainsOnPage: {},
    },
  },
  searchedFee: [],
  searchedComplain: [],
}

const slice = createSlice({
  name: 'fee',
  initialState,
  reducers: {
    SET_FEES: (state, action) => {
      state.fees.data = action.payload.fees
      if (state.fees.total.pages !== action.payload.total.pages) {
        state.fees.total.pages = action.payload.total.pages
      }
      if (state.fees.total.feesOnPage !== action.payload.total.feesOnPage) {
        state.fees.total.feesOnPage = action.payload.total.feesOnPage
      }
    },

    SET_SEARCHED_FEE: (state, action) => {
      state.searchedFee = action.payload
    },

    ACCEPT_FEE: (state, action) => {
      const { lawyerCode, tabType } = action.payload
      // delete Fee trong unpaid Fees
      // tim ra unpaidFee index
      const unpaidFeeIndex = state.fees.data[tabType].findIndex(fee => fee.lawyerCode === lawyerCode)
      // lay ra Fee đó
      const acceptedFee = state.fees.data[tabType].find(fee => fee.lawyerCode === lawyerCode)
      // Xóa fee được xác nhận khỏi array unpaidFees
      state.fees.data[tabType].splice(unpaidFeeIndex, 1)

      // Thêm vào paidFee / unpaidFee
      // chuyển vào paidFees /unpaidFee
      if (tabType === 1) {
        state.fees.data[0].unshift(acceptedFee)
        // lấy ra type cua tháng đã update
        const { type } = acceptedFee.unpaidFees[0]
        // tìm ra luật sư cần update unpaidfees
        const lawyerNeedToUpdateFee = state.fees.data[2].find(fee => fee.lawyerCode === lawyerCode)
        // tim ra index cua month cần xóa trong unpaidFees
        const monthIndex = lawyerNeedToUpdateFee.unpaidFees.findIndex(month => month.type === type)
        // xóa month trong unpaidFees
        lawyerNeedToUpdateFee.unpaidFees.splice(monthIndex, 1)
      }
      if (tabType === 0) {
        state.fees.data[1].unshift(acceptedFee)
        // lấy ra type cua tháng đã update
        // const { type } = acceptedFee.unpaidFees[0]
        console.log(acceptedFee)
        // tìm ra luật sư cần update unpaidfees
        const lawyerNeedToUpdateFee = state.fees.data[2].find(fee => fee.lawyerCode === lawyerCode)
        // them month vao unpaidFees
        lawyerNeedToUpdateFee.unpaidFees.splice(0, 0, acceptedFee.unpaidFees[0])
      }
    },

    // COMPLAINS
    SET_COMPLAINS: (state, action) => {
      state.complains.data = action.payload.complains

      if (state.complains.total.pages !== action.payload.total.pages) {
        state.complains.total.pages = action.payload.total.pages
      }

      if (state.complains.total.complainsOnPage !== action.payload.total.complainsOnPage) {
        state.complains.total.complainsOnPage = action.payload.total.complainsOnPage
      }
    },

    DELETE_COMPLAIN: (state, action) => {
      // find index of the complain that going to delete
      const complainIndex = state.complains.data[action.payload.tabType].findIndex(
        complain => complain.id === action.payload.id
      )
      state.complains.data[action.payload.tabType].splice(complainIndex, 1)
    },

    ACCEPT_COMPLAIN: (state, action) => {
      const { id, tabType } = action.payload
      console.log(action.payload)
      // delete Complain trong unresolved complains
      // tim ra unresolvedComplain index
      const acceptedComplainIndex = state.complains.data[tabType].findIndex(complain => complain.id === id)
      // Xóa fee được xác nhận khỏi array unpaidcomplains hoac nguoc lai

      if (tabType === 0) {
        // tim complain da thay doi
        const acceptedComplain = state.complains.data[0].find(complain => complain.id === id)
        acceptedComplain.isResolved = false
        state.complains.data[0].splice(acceptedComplainIndex, 1)

        // chuyen sang phan don khiue nai chua xu li
        state.complains.data[1].unshift(acceptedComplain)
      } else {
        // tim complain da thay doi
        const acceptedComplain = state.complains.data[1].find(complain => complain.id === id)
        acceptedComplain.isResolved = true
        state.complains.data[1].splice(acceptedComplainIndex, 1)
        // chuyen sang phan don khiue nai chua xu li
        state.complains.data[0].unshift(acceptedComplain)
      }
    },

    SET_SEARCHED_COMPLAIN: (state, action) => {
      state.searchedComplain = action.payload
    },
    RESET_SEARCHED_COMPLAIN: state => {
      state.searchedComplain.splice(0, state.searchedComplain.length)
    },
  },
})

const { reducer, actions } = slice

export const {
  SET_FEES,
  DELETE_FEE,
  ACCEPT_FEE,
  SET_SEARCHED_FEE,
  SET_COMPLAINS,
  ACCEPT_COMPLAIN,
  DELETE_COMPLAIN,
  SET_SEARCHED_COMPLAIN,
  RESET_SEARCHED_COMPLAIN,
} = actions

export default reducer
