import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'hooks'

import { styled } from '@mui/styles'
import { Box, Stack, Paper } from '@mui/material'
import HeaderLayout from './Header'

const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.common.white,
}))

export default function index() {
  const { isAuthenticated, handleAuthenticated } = useAuth()

  React.useEffect(() => {
    const token = localStorage.getItem('accessToken')
    if (!token) return
    handleAuthenticated()
  }, [isAuthenticated])

  return (
    <RootStyle>
      <HeaderLayout />
      <Box sx={{ height: theme => theme.sizes.header }} />

      <Stack
        sx={{
          mx: 3,
          bgcolor: 'common.white',
          height: theme => `calc(100% - ${theme.sizes.header}px)`,
          position: 'relative',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: '100%', height: '50%', position: 'absolute', top: 0, borderRadius: 2, overflow: 'hidden' }}>
          <img
            src={`
            https://appliendoan.s3.amazonaws.com/newsImages/banner.jpeg
          `}
            alt="banner"
            style={{ objectFit: 'cover' }}
          />
        </Box>

        <Paper
          sx={{
            width: 450,
            // minHeight: 500,
            zIndex: 1,
          }}
          elevation={7}
        >
          <Outlet />
        </Paper>
      </Stack>
    </RootStyle>
  )
}
