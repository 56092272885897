import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert } from 'hooks'

import { SET_FORUMS, SET_FORUM_DETAIL, DELETE_FORUM, SET_COMMENTS, DELETE_COMMENT } from 'stores/slices/ForumSlice'

const useForum = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()
  // tab
  const forums = useSelector(state => state.forum.forums)
  const forumsLength = useSelector(state => state.forum.forumsLength)
  const forumDetail = useSelector(state => state.forum.forumDetail)
  const forumDelete = useSelector(state => state.forum.forumDelete)

  const comments = useSelector(state => state.forum.comments)
  const commentsLength = useSelector(state => state.forum.commentsLength)
  const commentDelete = useSelector(state => state.forum.commentDelete)

  // handle

  const handleGetForums = async (page = 1) => {
    try {
      const response = await axios.get(`${GET_API().forums}?page=${page}`)
      console.log('handleGetForums', response)

      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          forums: response.data.posts.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }
        dispatch(SET_FORUMS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetCommentByPost = async (page = 1, postID) => {
    try {
      const response = await axios.get(`${GET_API(postID).commentByPost}?page=${page}`)
      console.log('handleGetCommentByPost', response)

      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          comments: response.data.comments.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }
        dispatch(SET_COMMENTS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetForumByID = async id => {
    try {
      const response = await axios.get(`${GET_API(id).forumByID}`)
      console.log('handleGetforumByID', response)
      if (response.data.status === 'success') {
        return response.data.post
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreatePost = async formData => {
    try {
      const response = await axios.post(POST_API().forums, formData)
      console.log('handleCreatePost', response)

      if (response.data.status === 'success') {
        enqueueSnackbar('Đăng bài thành công', { variant: 'success' })
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleCreateComment = async (formData, id) => {
    try {
      const response = await axios.post(POST_API(id).comments, formData)
      console.log('handleCreateComment', response)

      if (response.data.status === 'success') {
        enqueueSnackbar('Thêm bình luận thành công', { variant: 'success' })
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleDeletePost = async (forum = null, isAccept) => {
    if (!isAccept) return dispatch(DELETE_FORUM(forum))
    try {
      const response = await axios.delete(DELETE_API(forumDelete).forums)
      console.log('handleDeletePost', response)

      if (response.data.status === 'success') {
        const newForums = forums.filter(forum => forum?.id !== forumDelete)

        dispatch(SET_FORUMS({ forums: newForums }))

        enqueueSnackbar('Xóa bài đăng thành công', { variant: 'success' })
        dispatch(DELETE_FORUM(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleDeleteComment = async (comment = null, isAccept, postID) => {
    if (!isAccept) return dispatch(DELETE_COMMENT(comment))
    try {
      const response = await axios.delete(DELETE_API(postID, commentDelete).comments)
      console.log('handleDeleteComment', response)

      if (response.data.status === 'success') {
        const newComments = comments.filter(comment => comment?.id !== commentDelete)

        dispatch(SET_COMMENTS({ comments: newComments }))

        enqueueSnackbar('Xóa bình luận thành công', { variant: 'success' })
        dispatch(DELETE_COMMENT(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleVerifyPost = async (id, value, isComment = false) => {
    try {
      if (!isComment && value) {
        return enqueueSnackbar('Bài đăng này đã được phê duyệt', { variant: 'warning' })
      }
      if (isComment && value) {
        return enqueueSnackbar('Bình luận này đã được phê duyệt', { variant: 'warning' })
      }

      let response
      if (!isComment) {
        response = await axios.get(GET_API(id).verifyForum)
      } else {
        response = await axios.get(GET_API(id).verifyComment)
      }

      if (response.data.status === 'success') {
        const newsData = !isComment
          ? forums.map(forum => {
              if (forum?.id !== id) return forum
              return { ...forum, verified: true }
            })
          : comments.map(comment => {
              if (comment?.id !== id) return comment
              return { ...comment, verified: true }
            })

        enqueueSnackbar('Phê duyệt thành công', { variant: 'success' })
        !isComment ? dispatch(SET_FORUMS({ forums: newsData })) : dispatch(SET_COMMENTS({ comments: newsData }))
      }
    } catch (error) {
      console.log('err', error)
    }
  }
  return {
    forums,
    forumsLength,
    forumDetail,
    forumDelete,
    comments,
    commentsLength,
    commentDelete,
    handleCreateComment,
    handleDeleteComment,
    handleGetCommentByPost,
    handleCreatePost,
    handleVerifyPost,
    handleGetForums,
    handleGetForumByID,
    handleDeletePost,
  }
}

export default useForum
