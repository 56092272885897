import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuthenticated: false,
  user: {},
  isLoading: false,
  isRole: 0,
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    HANDLE_LOADING: (state, action) => {
      state.isLoading = action.payload
    },
    SET_USER: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
    },
    SET_ROLE: (state, action) => {
      state.isRole = action.payload
    },
    IS_AUTHENTICATED: (state, action) => {
      state.isAuthenticated = action.payload
    },
    HANDLE_LOGOUT: state => {
      state.isAuthenticated = false
      state.user = {}
    },
  },
})

const { reducer, actions } = slice

export const { SET_USER, HANDLE_LOADING, IS_AUTHENTICATED, SET_ROLE, HANDLE_LOGOUT } = actions

export default reducer
