import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/AuthSlice'
import newsReducer from './slices/NewsSlice'
import courseReducer from './slices/CourseSlice'
import pollReducer from './slices/PollSlice'
import userReducer from './slices/UserSlice'
import forumReducer from './slices/ForumSlice'
import agendaReducer from './slices/AgendaSlice'
import feeReducer from './slices/FeeSlice'
import filterReducer from './slices/FilterSlice'
import CDLSReducer from './slices/CDLSSlice'
import protectsReducer from './slices/Protects'

const rootReducer = {
  auth: authReducer,
  news: newsReducer,
  poll: pollReducer,
  course: courseReducer,
  user: userReducer,
  forum: forumReducer,
  agenda: agendaReducer,
  fee: feeReducer,
  CDLS: CDLSReducer,
  filter: filterReducer,
  protects: protectsReducer,
}

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['your/action/type'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['items.dates'],
      },
    }),
})
