import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert, useFilter } from 'hooks'

import {
  SET_FEES,
  DELETE_FEE,
  ACCEPT_FEE,
  SET_SEARCHED_FEE,
  SET_COMPLAINS,
  ACCEPT_COMPLAIN,
  DELETE_COMPLAIN,
  SET_SEARCHED_COMPLAIN,
} from 'stores/slices/FeeSlice'

const useFee = () => {
  const dispatch = useDispatch()
  const { filterTabType, filterDate, filterSearchText, setTabType } = useFilter()
  const { enqueueSnackbar } = useAlert()

  // tab
  const fees = useSelector(state => state.fee.fees.data)
  const totalFeesLength = useSelector(state => state.fee.fees.total)
  const feeDelete = useSelector(state => state.fee.feeDelete)
  const complains = useSelector(state => state.fee.complains.data)
  const totalComplainsLength = useSelector(state => state.fee.complains.total)
  const searchedComplain = useSelector(state => state.fee.searchedComplain)
  const searchedFee = useSelector(state => state.fee.searchedFee)

  // handle
  const handleGetFees = async (page, data, mode, current, type) => {
    try {
      const response = await axios.get(
        `${GET_API().fees}?pagePaid=${page}&pageUnpaid=${page}&pageSixMonth=${page}&year=${data.year}&month=${
          data.month
        }&mode=${mode}&current=${current}&type=fees`
      )
      console.log(response)
      const feesData = response.data

      if (!mode) {
        const allFees = []
        // get all values on data and only push the values there is array on a empty array variable
        Object.values(feesData).forEach(value => {
          if (Array.isArray(value)) {
            allFees.push(value)
          }
        })

        if (feesData.status === 'success') {
          const newResponse = {
            fees: allFees,
            total: {
              pages: {
                0: feesData.totalPaidPage,
                1: feesData.totalUnpaidPage,
                2: feesData.totalUnpaidSixMonthPage,
              },
              feesOnPage: {
                0: feesData.totalPaidLength,
                1: feesData.totalUnpaidLength,
                2: feesData.totalUnpaidSixMonthLength,
              },
            },
          }
          dispatch(SET_FEES(newResponse))
          if (newResponse.fees[filterTabType]?.length === 0) {
            enqueueSnackbar('Không có dữ liệu', { variant: 'warning' })
          }
          return
        }
      }
      console.log(feesData.result)
      return feesData.result
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetFeeByID = async id => {
    try {
      const response = await axios.get(GET_API(id).feeByID)
      // console.log('handleGetFeeByID', response)
      if (response.data.status === 'success') {
        return response.data.fee
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleUpdateFee = async (feeData, paidStatus) => {
    try {
      const { month, type } = paidStatus ? feeData.unpaidFees[0] : feeData.paidFees[0]
      // chỉ lấy year giống nhau
      // const year =
      //   tabType === 2
      //     ? data.unpaidFees[0]?.year
      //     : data.unpaidFees?.reduce((previousValue, currentValue) => {
      //         // tao ra 1 mang rong, gia tri khoi tao cung la []
      //         const result = []

      //         // lặp qua preValue, nếu year === curentCalue.year thì push vào [], Lúc này prev Value nhận dc 1 element
      //         if (previousValue.year !== currentValue.year) {
      //           result.push(currentValue.year)
      //         }
      //         return result
      //       }, [])
      const response = await axios.put(`${UPDATE_API().fee}?type=fees`, {
        lawyerCode: feeData.lawyerCode,
        feeType: type,
        month,
        year: filterDate.year,
        paidStatus,
      })
      console.log('handleUpdateFee', response)
      if (response.data.status === 'success') {
        enqueueSnackbar(
          `${
            paidStatus ? 'Đã chuyển phí sang trạng thái thanh toán' : 'Đã chuyển phí sang trạng thái chưa thanh toán'
          }`,
          { variant: 'success' }
        )
        dispatch(ACCEPT_FEE({ lawyerCode: feeData.lawyerCode, tabType: filterTabType }))
        if (filterTabType === 0) {
          setTabType(1)
        } else {
          setTabType(0)
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleUpdateFees = async feesData => {
    try {
      const { lawyerCode, unpaidFees } = feesData

      const response = await axios.put(`${UPDATE_API().fees}?type=fees`, {
        lawyerCode,
        feeTypes: unpaidFees.map(fee => fee.type),
        months: unpaidFees.map(fee => fee.month),
        year: filterDate.year,
      })
      console.log('handleUpdateFees', response)
      if (response.data.status === 'success') {
        enqueueSnackbar('Đã chuyển phí sang trạng thái thanh toán ', { variant: 'success' })
        setTabType(0)
      }
    } catch (error) {
      console.log('err', error)
    }
  }
  const getFeeByLawyerCodeAndFullName = async text => {
    try {
      if (text === '') {
        return dispatch(SET_SEARCHED_FEE([]))
      }
      const response = await axios.get(`${GET_API().searchFeeByLawyerCode}?searchText=${text}&type=fees`)
      if (response.data.status === 'success') {
        enqueueSnackbar('Tìm kiếm thành công ', { variant: 'success' })
        setTabType(2)
        dispatch(SET_SEARCHED_FEE(response.data.result))
      }
      // // gia tri fake, se truyen response vao
    } catch (error) {
      console.log('err', error)
    }
  }

  // complains

  const handleGetComplains = async page => {
    dispatch(SET_SEARCHED_COMPLAIN([]))

    try {
      const response = await axios.get(`${GET_API().complains}?resolvedPage=${page}&unResolvedPage=${page}&type=fees`)
      // const response = await axios.get(`${url}${GET_API().complains}?page=${page}`)
      console.log('handleGetComplains', response)
      const complainData = response.data

      if (complainData.status === 'success') {
        const allComplains = []
        // get all values on data and only push the values there is array on a empty array variable
        Object.values(complainData).forEach(value => {
          if (Array.isArray(value)) {
            allComplains.push(value)
          }
        })

        const newResponse = {
          complains: allComplains,
          total: {
            pages: {
              0: complainData.totalResolvedComplainsPage,
              1: complainData.totalUnresolvedComplainsPage,
            },
            complainsOnPage: {
              0: complainData.totalResolvedLength,
              1: complainData.totalUnresolvedLength,
            },
          },
        }
        dispatch(SET_COMPLAINS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const getComplainByLawyerNameAndDate = async (page, text, selectedDate, isResolved) => {
    console.log(text, selectedDate)
    try {
      if (text === '' && selectedDate === '') {
        dispatch(SET_SEARCHED_COMPLAIN([]))

        return
      }
      const response = await axios.post(`${POST_API().complains}?page=${page}&type=fees`, {
        searchText: text,
        selectedDate,
        isResolved,
      })
      if (response.data.status === 'success') {
        const newData = response.data.result
        // if (text !== '') {
        //   const { complains, ...restData } = response.data.result
        //   newData = complains.map(complain => {
        //     return {
        //       complain,
        //       ...restData,
        //     }
        //   })
        // } else {
        // newData = response.data.result
        // }
        dispatch(SET_SEARCHED_COMPLAIN(newData))
        enqueueSnackbar('Tìm kiếm thành công ', { variant: 'success' })
      }

      if (response.data.status === 'error') {
        enqueueSnackbar('Tìm kiếm thất bại ', { variant: 'warning' })
        dispatch(SET_SEARCHED_COMPLAIN([]))
      }
      // gia tri fake, se truyen response vao
    } catch (error) {
      console.log('err', error)
    }
  }
  const getComplainById = id => {
    let result
    // Loop parent array that are 2 children arrays : resolvedComplain and unresolvedComplain
    for (let i = 0; i < complains.length; i++) {
      // loop through per children-array, if some the id of some element i children array === param 'id', take it
      for (let j = 0; j < complains[i].length; j++) {
        if (complains[i][j].id === id) {
          result = complains[i][j]
        }
      }
    }
    return result
  }

  const handleUpdateComplain = async (id, resolvedStatus) => {
    try {
      const response = await axios.put(`${UPDATE_API(id).complain}&type=fees`, {
        resolvedStatus,
      })
      console.log('handleAcceptComplain', response)
      if (response.data.status === 'success') {
        enqueueSnackbar('Đơn khiếu nại đã được duyệt', { variant: 'success' })
        dispatch(ACCEPT_COMPLAIN({ id, tabType: filterTabType }))
        if (filterTabType === 0) {
          setTabType(1)
        } else {
          setTabType(0)
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    fees,
    totalFeesLength,
    feeDelete,
    complains,
    totalComplainsLength,
    searchedComplain,
    searchedFee,

    handleGetFees,
    handleGetFeeByID,
    handleUpdateFees,
    handleUpdateFee,
    getFeeByLawyerCodeAndFullName,
    getComplainByLawyerNameAndDate,
    handleGetComplains,
    getComplainById,
    handleUpdateComplain,
  }
}

export default useFee
