import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert } from 'hooks'

import { SET_AGENDAS, DELETE_AGENDA } from 'stores/slices/AgendaSlice'

const useNews = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  // tab
  const agendas = useSelector(state => state.agenda.agendas)
  const totalAgendasLength = useSelector(state => state.agenda.totalAgendasLength)
  const agendaDelete = useSelector(state => state.agenda.agendaDelete)

  // handle
  const handleGetAgendas = async (page = 1) => {
    try {
      const response = await axios.get(`${GET_API().agendas}?page=${page}`)

      console.log('handleGetAgenda ', response)
      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          agendas: response.data.agendas.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }
        dispatch(SET_AGENDAS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetAgendaByID = async id => {
    try {
      const response = await axios.get(GET_API(id).agendasById)
      console.log('handleGetAgendaByID', response)
      if (response.data.status === 'success') {
        return response.data.agenda
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreateAgenda = async form => {
    try {
      const { _id, ...restForm } = form
      let response
      if (!form?._id) {
        response = await axios.post(POST_API().agenda, form)
      } else {
        response = await axios.patch(UPDATE_API(form?._id).agenda, restForm)
      }
      console.log('handleCreateAgenda', response)
      if (response.data.status === 'success') {
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleDeleteAgenda = async (agenda = null, isAccept) => {
    if (!isAccept) return dispatch(DELETE_AGENDA(agenda))
    try {
      const response = await axios.delete(DELETE_API(agendaDelete).agenda)
      console.log('handleDeleteAgenda', response)

      if (response.data.status === 'success') {
        const newAgendas = agendas.filter(agenda => agenda?.id !== agendaDelete)

        dispatch(SET_AGENDAS({ agendas: newAgendas }))

        enqueueSnackbar('Xóa agenda thành công', { variant: 'success' })
        dispatch(DELETE_AGENDA(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    agendas,
    totalAgendasLength,
    agendaDelete,
    handleCreateAgenda,
    handleGetAgendaByID,
    handleGetAgendas,
    handleDeleteAgenda,
  }
}

export default useNews
