import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  date: {
    day: new Date().getDay(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  },
  searchText: '',
  tabType: 0,
}

const FilterSlice = createSlice({
  name: 'filter',

  initialState,
  reducers: {
    SET_DATE: (state, action) => {
      const keys = Object.keys(action.payload)

      keys.forEach(key => (state.date[key] = action.payload[key]))
    },
    SET_SEARCH_TEXT: (state, action) => {
      state.searchText = action.payload
    },
    SET_TAB_TYPE: (state, action) => {
      state.tabType = action.payload
    },
  },
})

const { reducer, actions } = FilterSlice

export const { SET_DATE, SET_SEARCH_TEXT, SET_TAB_TYPE } = actions

export default reducer
