import React from 'react'
import { FormControl, Typography, Select, MenuItem, FormHelperText } from '@mui/material'

const MSelect = props => {
  const { containerStyle, placeholder, label, lists, sx, value, ...restProps } = props

  return (
    <FormControl error={restProps?.error} sx={containerStyle}>
      <Typography variant="body2">{label}</Typography>
      <Select
        {...restProps}
        value={value}
        displayEmpty
        sx={{ borderRadius: 0.625, '& .MuiSelect-select': { py: 1.5, fontSize: 14 }, ...sx }}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {lists.map(item => (
          <MenuItem key={item.value} value={item.value} autoFocus={item.value === value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {restProps?.error && restProps?.helpertext && (
        <FormHelperText sx={{ ml: 0 }}>{restProps?.helpertext}</FormHelperText>
      )}
    </FormControl>
  )
}

export default MSelect
