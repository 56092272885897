import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { HANDLE_LOADING, SET_USER, HANDLE_LOGOUT } from 'stores/slices/AuthSlice'
import { isValidToken, setSession, getIdByToken } from 'utils/jwt'
import axiosInstance from 'utils/axios'
import { GET_API, POST_API } from 'routes/api'
import { useAlert } from 'hooks'

const useAuth = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  // redux
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const user = useSelector(state => state.auth.user)
  const isLoading = useSelector(state => state.auth.isLoading)

  // handle

  const handleAuthenticated = async (token = '') => {
    dispatch(HANDLE_LOADING(true))
    try {
      const accessToken = token || localStorage.getItem('accessToken')
      const getId = getIdByToken(accessToken)
      if (getId) {
        setSession(accessToken)
        const response = await axiosInstance.get(GET_API(getId).userById)
        // console.log('handleAuthenticated', response)

        dispatch(HANDLE_LOADING(false))
        if (response.data.status === 'success') {
          return dispatch(SET_USER(response.data.user))
        }
        return false
      }
      dispatch(HANDLE_LOGOUT())
      dispatch(HANDLE_LOADING(false))
      return false
    } catch (err) {
      console.log('err', err)
      dispatch(HANDLE_LOADING(false))
      dispatch(HANDLE_LOGOUT())
    }
  }

  const handleLogin = async form => {
    try {
      const response = await axiosInstance.post(POST_API().login, form)
      console.log(response)
      if (response.data.status === 'success') {
        if (response.data.lawyer.role !== 'admin') {
          enqueueSnackbar('Tài khoản này không phải là admin', { variant: 'error' })
          return false
        }
        localStorage.setItem('role', JSON.stringify(response.data.lawyer.role))
        handleAuthenticated(response.data.data)
        return true
      }
      enqueueSnackbar(response.data.message, { variant: 'error' })
      return false
    } catch (error) {
      console.log('err', error)
      return false
    }
  }

  const handleRegister = async form => {
    try {
      const response = await axiosInstance.post(POST_API().register, form)
      console.log('handleRegister', response)
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleLogout = () => {
    setSession('')
    dispatch(HANDLE_LOGOUT())
    window.localStorage.clear()
  }

  const handleForgotPassword = async ({ email }) => {
    try {
      const response = await axiosInstance.post(POST_API().forgotPwd, { email })
      enqueueSnackbar(response.data.message, { variant: response.data.status })
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleResetPassword = async ({ password, token }) => {
    try {
      const response = await axiosInstance.post(POST_API(token).resetPwd, { password })
      enqueueSnackbar(response.data.message, { variant: response.data.status })

      if (response.data.status === 'success') {
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  return {
    isAuthenticated,
    user,
    isLoading,
    handleForgotPassword,
    handleAuthenticated,
    handleLogin,
    handleRegister,
    handleLogout,
    handleResetPassword,
  }
}

export default useAuth
