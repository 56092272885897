import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow_left.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow_right.svg'
import { ReactComponent as ExcelIcon } from 'assets/icons/excel.svg'
import PdfIcon from 'assets/icons/pdf.png'

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchIcon from '@mui/icons-material/Search'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import SecurityIcon from '@mui/icons-material/Security'
import PublicIcon from '@mui/icons-material/Public'
import GroupsIcon from '@mui/icons-material/Groups'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import PersonIcon from '@mui/icons-material/Person'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp'

export default {
  ArrowBackIcon: <ArrowBackIcon />,
  SaveIcon: <SaveOutlinedIcon />,
  AttachMoneyIcon,
  SecurityIcon,
  PublicIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  GroupsIcon,
  PersonIcon,
  LocalLibraryIcon,
  CloseIcon: <CloseIcon />,
  DeleteIcon: <DeleteOutlineOutlinedIcon color="primary" />,
  DotIcon: <MoreVertOutlinedIcon />,
  ThreeDotIcon: <MoreVertRoundedIcon />,
  EditIcon: <EditOutlinedIcon color="primary" />,
  HomeIcon: HomeOutlinedIcon,
  MessageIcon: ChatBubbleOutlineOutlinedIcon,
  MoneyLineIcon: PaidOutlinedIcon,
  NewsIcon: NewspaperIcon,
  NotificationIcon: NotificationsNoneOutlinedIcon,
  AddIcon: <AddIcon />,
  PollIcon: PollOutlinedIcon,
  SearchIcon: <SearchIcon />,
  SettingIcon: SettingsOutlinedIcon,
  EyesIcon: <RemoveRedEyeOutlinedIcon sx={{ fontSize: 18 }} />,
  EyesCloseIcon: <VisibilityOffOutlinedIcon sx={{ fontSize: 18 }} />,
  ImageIcon: <ImageOutlinedIcon />,
  PostIcon: StickyNote2Icon,
  CloudIcon: <CloudUploadIcon />,
  PdfIcon,
  ExcelIcon: <ExcelIcon />,
  MenuIcon: <MenuIcon sx={{ fontSize: 18 }} />,
  MenuOpenIcon: <MenuOpenIcon sx={{ fontSize: 18 }} />,
  PrintIcon: <LocalPrintshopOutlinedIcon color="primary" />,
  ExpandLessIcon: <ExpandLess />,
  ExpandMoreIcon: <ExpandMore />,
  BookmarkAddedSharpIcon: <BookmarkAddedSharpIcon />,
}
