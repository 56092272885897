import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  courses: [],
  coursesLength: 0,
  courseDelete: null,
  courseDetail: {},
  // --------------
  courseUsers: {
    0: [],
    1: [],
  },
  courseUsersLength: {
    0: 0,
    1: 0,
  },
  tagCourseUser: 0,
  searchedUser: [],
  currentCourse: '',
  currentCourseFiles: [],
}

const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    SET_COURSES: (state, action) => {
      state.courses = action.payload.courses
      if (action.payload.totalLength) {
        state.coursesLength = action.payload.totalLength
      }
    },
    SET_COURSE_DETAIL: (state, action) => {
      state.courseDetail = action.payload
    },
    SET_CURRENT_COURSE: (state, action) => {
      state.currentCourse = action.payload
    },
    SET_COURSE_USERS: (state, action) => {
      state.courseUsers[action.payload.tag] = action.payload.courseUsers
      if (action.payload.totalLength) {
        state.courseUsersLength[action.payload.tag] = action.payload.totalLength
      }
    },
    ADD_USER_IN_COURSE: (state, action) => {
      action.payload.selectedUsers.forEach(user => {
        state.courseUsers[0].unshift(user)
      })

      if (action.payload.tag === 1) {
        action.payload.selectedUsers.forEach(user => {
          const currentIndex = state.courseUsers[1].find(courseUser => courseUser.id === user.id)
          state.courseUsers[1].splice(currentIndex, 1)
        })
        console.log(current(state.courseUsers[1]))
      }
    },
    SEARCH_USER_ON_DB: (state, action) => {
      state.searchedUser = action.payload
    },
    DELETE_COURSE: (state, action) => {
      state.courseDelete = action.payload
    },
    TAG_COURSE_USER: (state, action) => {
      state.tagCourseUser = action.payload
    },
    TOGGLE_COURSE_STATUS: (state, action) => {
      const currentCourse = state.courses.find(course => course.id === action.payload.id)
      currentCourse.status = action.payload.status
    },
    SET_CURRENT_COURSEFILES: (state, action) => {
      state.currentCourseFiles = action.payload
    },
  },
})

const { reducer, actions } = slice

export const {
  SET_COURSES,
  SET_CURRENT_COURSE,
  TAG_COURSE_USER,
  SET_COURSE_USERS,
  ADD_USER_IN_COURSE,
  SET_COURSE_DETAIL,
  DELETE_COURSE,
  TOGGLE_COURSE_STATUS,
  SEARCH_USER_ON_DB,
  SET_CURRENT_COURSEFILES,
} = actions

export default reducer
