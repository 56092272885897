import React from 'react'
import { Typography, Box } from '@mui/material'

const actionItemStyle = [
  { padding: 1, cursor: 'pointer', fontSize: 16 },
  theme => ({
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
]
function MActionListItem(props) {
  const { icons, onClick, text } = props
  return (
    <Typography component='div' sx={actionItemStyle} onClick={onClick}>
      <Box
        component="span"
        sx={{ minWidth: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        {text}
        {icons}
      </Box>
    </Typography>
  )
}

export default React.memo(MActionListItem)
