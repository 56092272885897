import React from 'react'
import { Pagination, Box } from '@mui/material'
import { useGridApiContext, useGridState } from '@mui/x-data-grid'

const PaginationCustom = ({ children, containerStyle }) => {
  const apiRef = useGridApiContext()
  const [state] = useGridState(apiRef)

  return (
    <Box sx={containerStyle}>
      {children}
      <Pagination
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
        sx={{
          '& .MuiPaginationItem-root': {
            fontSize: 12,
            minWidth: 28,
            height: 28,
            '& svg': {
              fill: theme => theme.palette.primary.main,
            },
          },
        }}
      />
    </Box>
  )
}

export default PaginationCustom
