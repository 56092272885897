import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import DateRangePicker from '@mui/lab/DateRangePicker'
import DesktopTimePicker from '@mui/lab/DesktopTimePicker'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { TextField, Box, Stack, Typography } from '@mui/material'
import { MTextField } from 'components/MUI'
import { vi } from 'date-fns/locale'

import { styled } from '@mui/styles'

const TextFieldRangeCustom = styled(TextField)(() => ({
  width: '100%',
  position: 'absolute',
  top: 0,
  height: '100%',
  '& input': { height: 0, fontSỉz: 14, cursor: 'pointer' },
  opacity: 0,
}))

const MDataPicker = React.forwardRef((props, ref) => {
  const { value, onBlur, onChange, isTimer, isDateRange, isDateAndTime, inputProps, ...restProps } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={vi} ref={ref}>
      {isDateRange ? (
        <DateRangePicker
          calendars={1}
          value={value }
          onChange={newValue => onChange(newValue)}
          onBlur={newValue => onBlur(newValue)}
          renderInput={(startProps, endProps) => {
            const formatStartDate = startProps.inputProps.value.split('/').reverse().join('/')
            const formatEndDate = endProps.inputProps.value.split('/').reverse().join('/')
            const start = new Date(formatStartDate)
            const end = new Date(formatEndDate)

            return (
              <Stack direction="row" spacing={0.5}>
                <Box sx={{ minWidth: 10, position: 'relative' }}>
                  <Typography variant="body2">
                    {`${start.getDate() || ''}`}
                    {start.getDate()
                      ? start.getMonth() + 1 !== end.getMonth() + 1
                        ? `/${start.getMonth() + 1}`
                        : ''
                      : ''}
                  </Typography>
                  <TextFieldRangeCustom
                    hiddenLabel
                    autoComplete="off"
                    sx={{
                      left: 0,
                    }}
                    {...startProps}
                    label=""
                  />
                </Box>
                <Box> - </Box>
                <Box sx={{ minWidth: 10, position: 'relative' }}>
                  <Typography variant="body2">
                    {`${endProps.inputProps.value ? `${endProps.inputProps.value}` : ''}`}
                  </Typography>

                  <TextFieldRangeCustom
                    hiddenLabel
                    autoComplete="off"
                    sx={{
                      right: 0,
                    }}
                    {...endProps}
                    label=""
                  />
                </Box>
              </Stack>
            )
          }}
        />
      ) : isTimer ? (
        <DesktopTimePicker
          {...restProps}
          value={value}
          onChange={newValue => onChange(newValue)}
          renderInput={params => <MTextField {...inputProps} {...params} />}
        />
      ) : isDateAndTime ? (
        <DateTimePicker
          {...restProps}
          value={value}
          onChange={newValue => onChange(newValue)}
          renderInput={params => <MTextField {...params} {...inputProps} />}
        />
      ) : (
        <DatePicker
          inputFormat="dd/MM/yyyy"
          {...restProps}
          value={value}
          onChange={newValue => onChange(newValue)}
          renderInput={params => <MTextField {...inputProps} {...params} />}
        />
      )}
    </LocalizationProvider>
  )
})

export default MDataPicker
