import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SET_CDLS } from 'stores/slices/CDLSSlice'
import axios from 'utils/axios'
import { GET_API } from 'routes/api'
import { useAlert } from 'hooks'

function useCDLS() {
  const provinces = useSelector(state => state.CDLS.provinces)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()
  const fetchingProvinces = async page => {
    try {
      const response = await axios.get(`${GET_API().analysisLawyers}`)
      const allProvinces = response.data.analysisLawyers.map(lawyer => {
        return {
          id: lawyer.provinceCode || 999,
          name: lawyer.province,
          ...lawyer,
        }
      })
      if (response.data.status === 'success') {
        dispatch(SET_CDLS(allProvinces))
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return {
    provinces,
    fetchingProvinces,
  }
}

export default useCDLS
