import React from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { styled } from '@mui/styles'

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiRadio-root': {
    padding: theme.spacing(0.5),
    '& .MuiSvgIcon-root ': {
      fontSize: 16,
    },
  },
  '& .MuiTypography-root': {
    fontSize: 14,
  },
}))

const MRadio = props => {
  const { name, value, lists, containerStyle, onChange, ...restProps } = props
  return (
    <FormControl component="fieldset" sx={{ ml: 2, ...containerStyle }}>
      <RadioGroup value={value} name={name} onChange={e => onChange(e.target.value)} {...restProps}>
        {lists?.map(item => (
          <FormControlLabelStyle key={item.label} value={item.value} control={<Radio />} label={item.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default MRadio
