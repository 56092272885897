import React from 'react'
import { Button, Typography } from '@mui/material'
import { styled } from '@mui/styles'

const MButton = React.forwardRef((props, ref) => {
  const { bgcolor, children, sx, ...restProps } = props

  return (
    <Button
      ref={ref}
      sx={{
        bgcolor,
        color: 'common.white',
        '&:hover': {
          color: bgcolor,
        },
        py: 1,
        textTransform: 'capitalize',
        borderRadius: theme => theme.sizes.borderRadiusButton,

        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Button>
  )
})

export default MButton
