import React from 'react'

const useStyles = () => {
  const lineClampStyle = (line = 1) => {
    return {
      display: ' -webkit-box',
      WebkitLineClamp: `${line}`,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textAlign: 'left',
    }
  }

  return { lineClampStyle }
}

export default useStyles
