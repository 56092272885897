import React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/styles'

const MTextField = props => {
  const { variant, sx, inputStyle, ...restProps } = props

  return (
    <TextField
      {...restProps}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 0.5,
          background: theme => theme.palette.common.white,
        },
        '& input': {
          ...inputStyle,
          fontSize: 14,
          '&::placeholder': {
            color: theme => theme.palette.border.main,
          },
        },
        '& .MuiInputLabel-root': {
          fontSize: 14,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme => theme.palette.border.main,
        },
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
        },
        ...sx,
      }}
    />
  )
}

export default MTextField
