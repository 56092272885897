import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert } from 'hooks'
import { SET_NQ_POLLS, SET_POLL_DETAIL, SET_MV_POLLS, TAG_POLL, DELETE_POLL } from 'stores/slices/PollSlice'

const usePoll = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  // redux
  const nqPolls = useSelector(state => state.poll.nqPolls)
  const mvPolls = useSelector(state => state.poll.mvPolls)
  const nqLength = useSelector(state => state.poll.nqLength)
  const mvLength = useSelector(state => state.poll.mvLength)
  const tagPoll = useSelector(state => state.poll.tagPoll)
  const pollDelete = useSelector(state => state.poll.pollDelete)
  const pollDetail = useSelector(state => state.poll.pollDetail)

  // handle
  const handleGetTypePolls = async (type, page = 1) => {
    console.log('handleGetTypePolls', type, page)
    try {
      const response = await axios.get(`${GET_API().polls}?page=${page}&type=${type}`)
      console.log(`polls res type: ${type}`, response)

      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          polls: response.data.polls.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }

        type === 'NQ' ? dispatch(SET_NQ_POLLS(newResponse)) : dispatch(SET_MV_POLLS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetPolls = async () => {
    await handleGetTypePolls('NQ')
    await handleGetTypePolls('MV')
  }

  const handleChangeTag = tag => dispatch(TAG_POLL(tag))

  const handleGetPollByID = async id => {
    try {
      const response = await axios.get(`${GET_API(id).pollByID}`)
      console.log('handleGetPollByID', response)

      if (response.data.status === 'success') {
        const { _id, ...restProps } = response.data.poll
        const newPoll = { id: _id, ...restProps }
        dispatch(SET_POLL_DETAIL(newPoll))
        return response.data.poll
      }
      enqueueSnackbar(response?.data?.message, { type: 'danger' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleUpdatePoll = async (pollID, form) => {
    try {
      const response = await axios.patch(UPDATE_API(pollID).polls, form)
      console.log(response)
      if (response.data.status === 'success') {
        // toast.show('Bình chọn thành công', { type: 'success' })
        return true
      }
      // toast.show(response.data.message, { type: 'danger' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreatePoll = async (form, pollType) => {
    try {
      let response
      if (pollType === 'NQ') {
        response = await axios.post(POST_API().pollsNQ, form)
      } else {
        response = await axios.post(POST_API().pollsMV, form)
      }
      console.log('handleCreatePoll', pollType, response)
      if (response.data.status === 'success') {
        return response.data.poll
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditPoll = async (form, pollType) => {
    try {
      const { id, ...restForm } = form
      const response = await axios.patch(UPDATE_API(id).poll, { ...restForm, type: pollType })
      console.log('handleEditPoll', { ...restForm, type: pollType }, response)

      if (response.data.status === 'success') {
        return response.data.poll
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleDeletePoll = async (poll = null, isAccept) => {
    if (!isAccept) return dispatch(DELETE_POLL(poll))
    try {
      const response = await axios.delete(DELETE_API(pollDelete).poll, { data: { type: tagPoll } })
      console.log('handleDeletePolll', { type: tagPoll }, response)

      if (response.data.status === 'success') {
        if (tagPoll === 'NQ') {
          const newPoll = nqPolls.filter(poll => poll?.id !== pollDelete)
          console.log('newPoll', pollDelete, newPoll)

          dispatch(SET_NQ_POLLS({ polls: newPoll, isAction: true }))
        } else {
          const newPoll = mvPolls.filter(poll => poll?.id !== pollDelete)
          dispatch(SET_MV_POLLS({ polls: newPoll, isAction: true }))
        }
        enqueueSnackbar('Xóa biểu quyết thành công', { variant: 'success' })
        dispatch(DELETE_POLL(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleUpdateStatusPoll = async (pollID, value, isDetail = false) => {
    try {
      const response = await axios.patch(UPDATE_API(pollID).pollStatus, { status: value === 'Open' ? 'Close' : 'Open' })
      if (response.data.status === 'success') {
        if (!isDetail) {
          if (tagPoll === 'NQ') {
            const newPoll = nqPolls.map(poll => {
              if (poll.id !== pollID) return poll
              return { ...poll, status: response.data.poll.status }
            })
            dispatch(SET_NQ_POLLS({ polls: newPoll, isAction: true }))
          } else {
            const newPoll = mvPolls.map(poll => {
              if (poll.id !== pollID) return poll
              return { ...poll, status: response.data.poll.status }
            })
            dispatch(SET_MV_POLLS({ polls: newPoll, isAction: true }))
          }
        } else {
          const newPoll = { ...pollDetail, status: response.data.poll.status }
          dispatch(SET_POLL_DETAIL(newPoll))
        }

        enqueueSnackbar('Cập nhập trạng thái thành công', { variant: 'success' })
      }
    } catch (error) {
      console.log('er', error)
    }
  }

  return {
    tagPoll,
    nqPolls,
    mvPolls,
    nqLength,
    mvLength,
    pollDelete,
    pollDetail,
    handleChangeTag,
    handleGetPolls,
    handleGetPollByID,
    handleUpdatePoll,
    handleGetTypePolls,
    handleCreatePoll,
    handleEditPoll,
    handleDeletePoll,
    handleUpdateStatusPoll,
  }
}

export default usePoll
