// THIS IS THE DEFAULT VALUE YOU CAN CHANGE IF YOU WANT
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1280,
    xl: 1920,
    mobile: 420,
  },
}

export default breakpoints
