import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert } from 'hooks'
import htmlToReact from 'utils/htmlToReact'
import shortenUrls from 'utils/shortenUrl'

import { SET_NEWS, DELETE_NEW, SET_CURRENT_BANNER } from 'stores/slices/NewsSlice'

const useNews = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useAlert()

  // tab
  const news = useSelector(state => state.news.news)
  const totalNewsLength = useSelector(state => state.news.totalNewsLength)
  const newsDelete = useSelector(state => state.news.newsDelete)
  const currentBanner = useSelector(state => state.news.currentBanner)

  // handle
  const handleGetNews = async (page = 1) => {
    try {
      const response = await axios.get(`${GET_API().news}?page=${page}&type=All`)
      console.log('handleGetNews ', response)
      if (response.data.status === 'success') {
        const newResponse = {
          ...response.data,
          news: response.data.news.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }

        dispatch(SET_NEWS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleGetNewsByID = async id => {
    try {
      const response = await axios.get(GET_API(id).newsById)
      if (response.data.status === 'success') {
        const newNews = { ...response.data.new, html: htmlToReact(response.data.new.html) }
        return newNews
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleCreateNews = async (html, title) => {
    try {
      const formdata = new FormData()

      const shortenedHtml = await shortenUrls(title)

      console.log('shortenedHtml', shortenedHtml)

      formdata.append('title', html)
      formdata.append('type', 'All')
      formdata.append('html', title)
      if (currentBanner[0]) {
        formdata.append('file', currentBanner[0])
      }

      const response = await axios.post(POST_API().news, formdata)
      console.log('handleCreateNews', response)
      if (response.data.status === 'success') {
        enqueueSnackbar('Tạo tin tức thành công', { variant: 'success' })
        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditNews = async (title, html, id) => {
    try {
      const formdata = new FormData()

      formdata.append('title', title)
      formdata.append('type', 'All')
      formdata.append('html', html)
      if (currentBanner[0]) {
        formdata.append('file', currentBanner[0])
      }

      const response = await axios.patch(UPDATE_API(id).news, formdata)

      if (response.data.status === 'success') {
        enqueueSnackbar('Sửa tin tức thành công', { variant: 'success' })
        console.log(response)

        return true
      }
      return false
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleDeleteNews = async (newsID = null, isAccept) => {
    if (!isAccept) return dispatch(DELETE_NEW(newsID))
    try {
      const response = await axios.delete(DELETE_API(newsDelete).news)
      console.log('handleDeleteNews', response)

      if (response.data.status === 'success') {
        const newsData = news.filter(value => value?.id !== newsDelete)

        dispatch(SET_NEWS({ news: newsData }))

        enqueueSnackbar('Xóa tin tức thành công', { variant: 'success' })
        dispatch(DELETE_NEW(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleSetCurrentBanner = async file => {
    dispatch(SET_CURRENT_BANNER(file))
  }

  return {
    news,
    totalNewsLength,
    newsDelete,
    handleEditNews,
    handleCreateNews,
    handleDeleteNews,
    handleGetNews,
    handleGetNewsByID,
    currentBanner,
    handleSetCurrentBanner,
  }
}

export default useNews
