import React from 'react'
import { Button, Popover ,Box} from '@mui/material'

function MPopover(props) {
  const { listItems, popoverId, title, text, icons } = props
  const [showPopover, setShowPopover] = React.useState(null)
  const open = Boolean(showPopover)
  const id = open ? `${popoverId}` : undefined
  return (
    <>
      <Button
        title={title}
        onClick={e => setShowPopover(e.currentTarget)}
        endIcon={icons}
        variant="text"
        sx={{ cursor: 'pointer', display: 'flex', alignItem: 'center' }}
      >
        {text || ''}
      </Button>
      <Popover
        id={id}
        open={open}
        onClose={() => setShowPopover(null)}
        anchorEl={showPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box onClick={() => setShowPopover(null)}>{listItems}</Box>
      </Popover>
    </>
  )
}

export default React.memo(MPopover)
