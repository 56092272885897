import React from 'react'
import { Stack, Typography } from '@mui/material'
import { MTextField } from 'components/MUI'

const MFormControlCustom = props => {
  const { label, children } = props
  return (
    <Stack sx={{ flex: 1 }}>
      <Typography variant="body2">{label}</Typography>
      {children}
    </Stack>
  )
}

export default MFormControlCustom
