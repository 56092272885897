import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  agendas: [],
  totalAgendasLength: 0,
  agendaDelete: null,
}

const slice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    SET_AGENDAS: (state, action) => {
      state.agendas = action.payload.agendas
      if (state.totalAgendasLength === 0 && action.payload.totalLength) {
        state.totalAgendasLength = action.payload.totalLength
      }
    },
    DELETE_AGENDA: (state, action) => {
      state.agendaDelete = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { SET_AGENDAS, DELETE_AGENDA } = actions

export default reducer
