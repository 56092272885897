import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert, useFilter } from 'hooks'

import { SET_COMPLAINS, ACCEPT_COMPLAIN, DELETE_COMPLAIN, SET_SEARCHED_COMPLAIN } from 'stores/slices/Protects'

const useProtects = () => {
  const dispatch = useDispatch()
  const { filterTabType, filterDate, filterSearchText, setTabType } = useFilter()
  const { enqueueSnackbar } = useAlert()

  // tab
  const complains = useSelector(state => state.protects.complains.data)
  const totalComplainsLength = useSelector(state => state.protects.complains.total)
  const searchedComplain = useSelector(state => state.protects.searchedComplain)

  // complains

  const handleGetComplains = async page => {
    dispatch(SET_SEARCHED_COMPLAIN([]))

    try {
      const response = await axios.get(`${GET_API().complains}?resolvedPage=${page}&unResolvedPage=${page}`)
      // const response = await axios.get(`${url}${GET_API().complains}?page=${page}`)
      const complainData = response.data

      if (complainData.status === 'success') {
        const allComplains = []
        // get all values on data and only push the values there is array on a empty array variable
        Object.values(complainData).forEach(value => {
          if (Array.isArray(value)) {
            allComplains.push(value)
          }
        })

        const newResponse = {
          complains: allComplains,
          total: {
            pages: {
              0: complainData.totalResolvedComplainsPage,
              1: complainData.totalUnresolvedComplainsPage,
            },
            complainsOnPage: {
              0: complainData.totalResolvedLength,
              1: complainData.totalUnresolvedLength,
            },
          },
        }

        dispatch(SET_COMPLAINS(newResponse))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const getComplainByLawyerNameAndDate = async (page, text, selectedDate, isResolved) => {
    console.log(text, selectedDate)
    try {
      if (text === '' && selectedDate === '') {
        dispatch(SET_SEARCHED_COMPLAIN([]))

        return
      }
      const response = await axios.post(`${POST_API().complains}?page=${page}`, {
        searchText: text,
        selectedDate,
        isResolved,
      })
      console.log(response)
      if (response.data.status === 'success') {
        const newData = response.data.result
        
        dispatch(SET_SEARCHED_COMPLAIN(newData))
        enqueueSnackbar('Tìm kiếm thành công ', { variant: 'success' })
      }

      if (response.data.status === 'error') {
        enqueueSnackbar('Tìm kiếm thất bại ', { variant: 'warning' })
        dispatch(SET_SEARCHED_COMPLAIN([]))
      }
      // gia tri fake, se truyen response vao
    } catch (error) {
      console.log('err', error)
    }
  }
  const getComplainById = id => {
    let result
    // Loop parent array that are 2 children arrays : resolvedComplain and unresolvedComplain
    for (let i = 0; i < complains.length; i++) {
      // loop through per children-array, if some the id of some element i children array === param 'id', take it
      for (let j = 0; j < complains[i].length; j++) {
        if (complains[i][j].id === id) {
          result = complains[i][j]
        }
      }
    }
    return result
  }

  const handleUpdateSupportsComplain = async (id, resolvedStatus) => {
    try {
      const response = await axios.put(UPDATE_API(id).complain, {
        resolvedStatus,
      })
      console.log('handleAcceptComplain', response)
      if (response.data.status === 'success') {
        enqueueSnackbar('Đơn khiếu nại đã được duyệt', { variant: 'success' })
        dispatch(ACCEPT_COMPLAIN({ id, tabType: filterTabType }))
        if (filterTabType === 0) {
          setTabType(1)
        } else {
          setTabType(0)
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  return {
    complains,
    totalComplainsLength,
    searchedComplain,
    getComplainByLawyerNameAndDate,
    handleGetComplains,
    getComplainById,
    handleUpdateSupportsComplain,
  }
}

export default useProtects
