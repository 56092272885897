import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  analysis: [],
  lawyers: [],
  usersLength: 0,
  lawyersLength: 0,
  lists: {
    0: [],
    1: [],
  },
  listsLength: {
    0: 0,
    1: 0,
  },
  tagUser: 0,
  userDelete: null,
  userDetail: {},
  isOpenDialog: false,
  filterDate: { year: new Date().getFullYear() },
  searchedLawyers: [],
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_USERS: (state, action) => {
      state.lists[action.payload.currentTag] = action.payload.data
      if (action.payload.totalLength) {
        state.listsLength[action.payload.currentTag] = action.payload.totalLength
      }
    },
    SET_NEW_USER: (state, action) => {
      console.log(action.payload)
      state.lists[state.tagUser].unshift(action.payload)
    },
    TOGGLE_DIALOG: (state, action) => {
      state.isOpenDialog = action.payload
      if (!state.isOpenDialog) {
        state.userDetail = {}
      }
    },
    SET_USER_DETAIL: (state, action) => {
      state.userDetail = action.payload
    },
    TAG_USER: (state, action) => {
      state.tagUser = action.payload
    },
    DELETE_USER: (state, action) => {
      state.userDelete = action.payload
    },
    FILTER_DATE: (state, action) => {
      state.filterDate = action.payload
    },
    SEARCH_LAWYERS: (state, action) => {
      state.searchedLawyers = action.payload
    },
    TOGGLE_DISABLE_LAWYER: (state, action) => {
      const currentLawyer = state.lists[state.tagUser].find(lawyer => lawyer.id === action.payload.id)
      currentLawyer.isDisabled = action.payload.isDisabled
    },
  },
})

const { reducer, actions } = slice

export const {
  SET_LAWYERS,
  SET_NEW_USER,
  SET_USERS,
  SET_USER_DETAIL,
  TOGGLE_DISABLE_LAWYER,
  TAG_USER,
  DELETE_USER,
  TOGGLE_DIALOG,
  FILTER_DATE,
  SEARCH_LAWYERS,
} = actions

export default reducer
