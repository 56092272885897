import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  news: [],
  totalNewsLength: 0,
  newsDelete: null,
  currentBanner: {},
}

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    SET_NEWS: (state, action) => {
      state.news = action.payload.news
      if (state.totalNewsLength === 0 && action.payload.totalLength) {
        state.totalNewsLength = action.payload.totalLength
      }
    },
    DELETE_NEW: (state, action) => {
      state.newsDelete = action.payload
    },
    SET_CURRENT_BANNER: (state, action) => {
      state.currentBanner = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { SET_NEWS, DELETE_NEW, SET_CURRENT_BANNER } = actions

export default reducer
