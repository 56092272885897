import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'hooks'

import { styled } from '@mui/styles'
import { Box, Stack, Paper, Typography } from '@mui/material'
// component
import HeaderLayout from './Header'
import SidebarLayout from './Sidebar'
import { PATH_PAGE } from 'routes/paths'

const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.common.white,
}))

export default function index() {
  const location = useLocation()
  const { isAuthenticated, handleAuthenticated } = useAuth()
  const { pathname } = location
  const [toggleHamburger, setToggleHamburger] = React.useState(false)

  React.useEffect(() => {
    if (isAuthenticated) return
    handleAuthenticated()
  }, [isAuthenticated])

  const handleToggleHamburger = () => setToggleHamburger(!toggleHamburger)

  return (
    <RootStyle>
      <HeaderLayout open={!toggleHamburger} handleToggleHamburger={handleToggleHamburger} />
      <Box sx={{ height: theme => theme.sizes.header }} />

      {/* <Box sx={{ height: theme => `calc(100vh - ${theme.sizes.header}px)` }}> */}
      <Box>
        <SidebarLayout open={!toggleHamburger} handleToggleHamburger={handleToggleHamburger} />
        <Box
          sx={[
            {
              height: '100%',
              width: theme => (!toggleHamburger ? `calc(100% - ${theme.sizes.sidebar}px)` : '100%'),
              float: 'right',
              px: 2,
              py: 2.5,
              transition: '100ms',
            },
            theme => ({
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }),
          ]}
        >
          <Outlet />
        </Box>
      </Box>
    </RootStyle>
  )
}
