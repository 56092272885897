import React from 'react'
import { useSnackbar } from 'notistack'

const acceptImage = ['png', 'jpg', 'jpeg']

export default function useUploadFiles(type) {
  const [sourceImg, setSourceImg] = React.useState([])
  const [imgFiles, setImgFiles] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()

  // ------step 2----
  const validateFile = (info, { validateSize = 10, validateExt = acceptImage }) => {
    const { name, size } = info
    const regex = new RegExp('[^.]+$')
    const extension = name.match(regex)
    const mb = size / 1000000

    const find = validateExt.findIndex(ext => ext === extension[0])
    if (find === -1) {
      enqueueSnackbar('File invalid', { variant: 'warning' })
      return false
    }
    if (mb > validateSize) {
      enqueueSnackbar(`File maximum ${validateSize}mb`, { variant: 'warning' })
      return false
    }
    return true
  }
  // ---------step 3------------

  let arrNew = []
  const arrSource = []
  let timer = null

  const handleNewField = ({ file, ...props }) => {
    arrNew.push(file)
    arrSource.push(props)

    if (file) {
      clearTimeout(timer)
    }

    // setDocFiles
    timer = setTimeout(() => {
      setImgFiles([...imgFiles, ...arrNew])
      setSourceImg([...arrSource, ...sourceImg])
      arrNew = []
    }, 1000)
  }

  // reset
  const handleReset = () => {
    console.log('rset arrNew arrSource', arrNew, arrSource)
    setImgFiles([])
    setSourceImg([])
    arrNew = []
  }

  // ------ step 1 ----------
  const handleChangeFile = (event, validate) => {
    const { files } = event.target

    const response = Object.entries(files).map(key => validateFile(key[1], validate))
    const results = response.every(result => result)

    // files is : [true, true, false] => return false
    if (!results) {
      return enqueueSnackbar('Upload failed', { variant: 'error' })
    }

    Object.entries(files).map(file => {
      const reader = new FileReader()
      reader.onload = e =>
        handleNewField({ file: file[1], source: e.target.result, id: Math.floor(Math.random() * 100000000 + 1) })
      file && reader.readAsDataURL(file[1])
      return false
    })
  }

  // ---- remove image example
  const handleRemoveFile = id => {
    const getIndex = sourceImg.findIndex(source => source.id === id)
    const newSource = sourceImg.filter(source => source.id !== id)
    const newFiles = imgFiles
    newFiles.splice(getIndex, 1)

    setSourceImg(newSource)
    setImgFiles(newFiles)
  }

  return { handleChangeFile, sourceImg, imgFiles, validateFile, handleRemoveFile, handleReset }
}
