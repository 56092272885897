import { Suspense, lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

// layouts
import MainLayout from 'layouts/main'
import AuthLayout from 'layouts/authentication'

// guards
import GuestGuard from 'guards/GuestGuard'
import AuthGuard from 'guards/AuthGuard'

// components
import LoadingScreen from 'components/LoadingScreen'

// ----------------------------------------------------------------------

const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },

        {
          path: 'reset-password',
          element: <ResetPassword />,
          children: [
            { path: '', element: <Navigate to="/1" replace /> },
            { path: ':token', element: <ResetPassword /> },
          ],
        },
      ],
    },

    // ERROR PAGE
    // {
    //   path: '*',
    //   element: <Error404 />,
    // },

    // Main Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/courses" replace /> },
        {
          path: 'courses',
          element: <CoursePage />,
          children: [
            { path: '', element: <CourseContainer /> },
            { path: ':id', element: <CourseDetail /> },
            { path: ':id/edit', element: <CourseCreate /> },
            { path: 'add', element: <CourseCreate /> },
          ],
        },
        {
          path: 'fees',
          element: <FeePage />,
          children: [
            { path: '', element: <FeeContainer /> },
            { path: 'complains', element: <FeeComplain /> },
            { path: 'complains/:id', element: <FeeComplainDetail /> },

            { path: 'insurances', element: <FeeInsurance /> },
          ],
        },
        {
          path: 'users',
          element: <UserPage />,
          children: [
            {
              path: 'admin',
              element: <UserAdmin />,
            },
            { path: ':id', element: <UserDetail /> },
          ],
        },

        {
          path: 'forums',
          element: <ForumPage />,
          children: [
            { path: '', element: <ForumContainer /> },
            { path: ':id', element: <ForumDetail /> },
            { path: ':id/comments', element: <CommentsOfForum /> },
            { path: ':id/edit', element: <ForumCreate /> },
            { path: 'add', element: <ForumCreate /> },
            { path: ':id/comment/add', element: <CommentCreate /> },
          ],
        },
        {
          path: 'boards',
          element: <BoardPage />,

          children: [
            { path: '', element: <Navigate to="/boards/meetings" replace /> },
            { path: 'meetings', element: <MeetingContainer /> },
            { path: 'plans', element: <PlanContainer /> },
          ],
        },
        {
          path: 'protects',
          element: <ProtectPage />,
          children: [
            { path: '', element: <Navigate to="/protects/supports" replace /> },
            { path: 'supports', element: <SupportContainer /> },
            { path: 'documents', element: <DocumentContainer /> },
          ],
        },
        {
          path: 'CDLS',
          element: <CDLSPage />,
          children: [
            { path: '', element: <CDLSContainer /> },
            {
              path: ':code/:label',
              element: <CDLSDetail />,
              children: [
                {
                  path: 'users',
                  element: <UserPage />,
                  children: [
                    { path: '', element: <UserContainer /> },
                    { path: ':id', element: <UserDetail /> },
                  ],
                },
                {
                  path: 'news',
                  element: <NewsPage />,
                  children: [
                    { path: '', element: <NewsContainer /> },
                    { path: ':id', element: <NewsDetail /> },
                    { path: ':id/edit', element: <NewsCreate /> },
                    { path: 'add', element: <NewsCreate /> },
                  ],
                },
                {
                  path: 'polls',
                  element: <PollsPage />,
                  children: [
                    { path: '', element: <PollsContainer /> },
                    { path: ':id', element: <PollDetail /> },
                    { path: ':id/edit', element: <PollAdd /> },
                    { path: 'add', element: <PollAdd /> },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: 'HDLSTQ',
          element: <HDLSTQPage />,
          children: [
            { path: '', element: <Navigate to="/HDLSTQ/news" replace /> },
            {
              path: 'news',
              element: <NewsPage />,
              children: [
                { path: '', element: <NewsContainer /> },
                { path: ':id', element: <NewsDetail /> },
                { path: ':id/edit', element: <NewsCreate /> },
                { path: 'add', element: <NewsCreate /> },
              ],
            },
            {
              path: 'polls',
              element: <PollsPage />,
              children: [
                { path: '', element: <PollsContainer /> },
                { path: ':id', element: <PollDetail /> },
                { path: ':id/edit', element: <PollAdd /> },
                { path: 'add', element: <PollAdd /> },
              ],
            },
            {
              path: 'chats',
              element: <ChatContainer />,
            },
            {
              path: 'agenda',
              element: <AgendasPage />,
              children: [
                { path: '', element: <AgendasContainer /> },
                { path: ':id', element: <AgendasDetail /> },
                { path: ':id/edit', element: <AgendasCreate /> },
                { path: 'add', element: <AgendasCreate /> },
              ],
            },
          ],
        },
        {
          path: 'TVLD',
          element: <TVLDPage />,
          children: [
            { path: '', element: <Navigate to="/TVLD/news" replace /> },
            {
              path: 'news',
              element: <NewsPage />,
              children: [
                { path: '', element: <NewsContainer /> },
                { path: ':id', element: <NewsDetail /> },
                { path: ':id/edit', element: <NewsCreate /> },
                { path: 'add', element: <NewsCreate /> },
              ],
            },
            {
              path: 'polls',
              element: <PollsPage />,
              children: [
                { path: '', element: <PollsContainer /> },
                { path: ':id', element: <PollDetail /> },
                { path: ':id/edit', element: <PollAdd /> },
                { path: 'add', element: <PollAdd /> },
              ],
            },
          ],
        },
        {
          path: 'news',
          element: <NewsPage />,
          children: [
            { path: '', element: <NewsContainer /> },
            { path: ':id', element: <NewsDetail /> },
            { path: ':id/edit', element: <NewsCreate /> },
            { path: 'add', element: <NewsCreate /> },
          ],
        },
      ],
    },
  ])
}

// Authentication
const Login = Loadable(lazy(() => import('pages/Authentication/Login')))
const Register = Loadable(lazy(() => import('pages/Authentication/Register')))
const ResetPassword = Loadable(lazy(() => import('pages/Authentication/ResetPassword')))
const ForgotPassword = Loadable(lazy(() => import('pages/Authentication/ForgotPassword')))

// ERROR
const Error404 = Loadable(lazy(() => import('pages/Error404')))

// dashboard
const LandingPage = Loadable(lazy(() => import('pages/main/Landing')))

// news
const NewsPage = Loadable(lazy(() => import('pages/News/NewsPage')))
const NewsContainer = Loadable(lazy(() => import('pages/News/NewsContainer')))
const NewsDetail = Loadable(lazy(() => import('pages/News/NewsDetail')))
const NewsCreate = Loadable(lazy(() => import('pages/News/NewsCreate')))

// agenda
const AgendasPage = Loadable(lazy(() => import('pages/Agenda/AgendaPage')))
const AgendasContainer = Loadable(lazy(() => import('pages/Agenda/AgendaContainer')))
const AgendasDetail = Loadable(lazy(() => import('pages/Agenda/AgendaDetail')))
const AgendasCreate = Loadable(lazy(() => import('pages/Agenda/AgendaCreate')))

// course
const CoursePage = Loadable(lazy(() => import('pages/Courses/CoursePage')))
const CourseContainer = Loadable(lazy(() => import('pages/Courses/CourseContainer')))
const CourseDetail = Loadable(lazy(() => import('pages/Courses/CourseDetail')))
const CourseCreate = Loadable(lazy(() => import('pages/Courses/CourseCreate')))

// user
const UserPage = Loadable(lazy(() => import('pages/Users/UserPage')))
const UserContainer = Loadable(lazy(() => import('pages/Users/UserContainer')))
const UserDetail = Loadable(lazy(() => import('pages/Users/UserDetail')))
const UserAdmin = Loadable(lazy(() => import('pages/Users/UserAdmin')))

// poll
const PollsPage = Loadable(lazy(() => import('pages/Polls/PollsPage')))
const PollsContainer = Loadable(lazy(() => import('pages/Polls/PollsContainer')))
const PollDetail = Loadable(lazy(() => import('pages/Polls/PollDetail')))
const PollAdd = Loadable(lazy(() => import('pages/Polls/PollAdd')))

// forums
const ForumPage = Loadable(lazy(() => import('pages/Forums/ForumPage')))
const ForumCreate = Loadable(lazy(() => import('pages/Forums/ForumCreate')))
const ForumContainer = Loadable(lazy(() => import('pages/Forums/ForumContainer')))
const ForumDetail = Loadable(lazy(() => import('pages/Forums/ForumDetail')))
const CommentsOfForum = Loadable(lazy(() => import('pages/Forums/CommentsOfForum')))
const CommentCreate = Loadable(lazy(() => import('pages/Forums/CommentCreate')))

// fees
const FeeContainer = Loadable(lazy(() => import('pages/Fees/FeeContainer')))
const FeePage = Loadable(lazy(() => import('pages/Fees/FeePage')))
const FeeInsurance = Loadable(lazy(() => import('pages/Fees/FeeInsurance')))
const FeeComplain = Loadable(lazy(() => import('pages/Fees/FeeComplain')))
const FeeComplainDetail = Loadable(lazy(() => import('pages/Fees/FeeComplainDetail')))

// protect
const ProtectPage = Loadable(lazy(() => import('pages/Protects/ProtectPage')))
const SupportContainer = Loadable(lazy(() => import('pages/Protects/SupportContainer')))
const DocumentContainer = Loadable(lazy(() => import('pages/Protects/DocumentContainer')))

// board
const BoardPage = Loadable(lazy(() => import('pages/Boards/BoardPage')))
const MeetingContainer = Loadable(lazy(() => import('pages/Boards/MeetingContainer')))
const PlanContainer = Loadable(lazy(() => import('pages/Boards/PlanContainer')))

// chat

// CDLS
const CDLSPage = Loadable(lazy(() => import('pages/CDLS/CDLSPage')))
const CDLSContainer = Loadable(lazy(() => import('pages/CDLS/CDLSContainer')))
const CDLSDetail = Loadable(lazy(() => import('pages/CDLS/CDLSDetail')))

const HDLSTQPage = Loadable(lazy(() => import('pages/HDLSTQ/HDLSTQPage')))
const ChatContainer = Loadable(lazy(() => import('pages/HDLSTQ/ChatContainer')))

const TVLDPage = Loadable(lazy(() => import('pages/TVLD/TVLDPage')))
