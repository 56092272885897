import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'

async function shortenUrls(htmlString) {
  // regex to get only the base64 image
  const regex = /<img .*?src=['"]data:image\/(.*?);base64,([^\s'"]+)['"]/g

  const matches = htmlString.match(regex) || []

  let newHtmlString = htmlString

  const promises = matches.map(async match => {
    const base64Data = match[1]

    const bufferData = Buffer.from(base64Data.replace(/^data:image\/\w+;base64,/, ''), 'base64')

    // Call api to upload the image
    const response = await axios.post(POST_API().upNewsImge, {
      image: bufferData,
    })

    const { data } = response

    console.log('data', response)

    newHtmlString = newHtmlString.replace(match, `<img src="${data.url}" />`)
  })

  await Promise.all(promises)

  return newHtmlString
}

export default shortenUrls
