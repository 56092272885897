function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_PAGE = '/'

// ----------------------------------------------------------------------

const PATH_AUTH = {
  root: { path: ROOTS_PAGE },
  login: { path: path(ROOTS_AUTH, '/login'), title: 'Đăng nhập' },
  register: { path: path(ROOTS_AUTH, '/register'), title: 'Đăng ký' },
  forgotPassword: { path: path(ROOTS_AUTH, '/forgot-password'), title: 'Quên mật khẩu' },
  resetPassword: { path: path(ROOTS_AUTH, '/reset-password'), title: 'Cập nhập mật khẩu' },
}

const PATH_PAGE = {
  users: { path: path(ROOTS_PAGE, 'users'), title: 'Quản lí luật sư', content: 'luật sư' },
  courses: { path: path(ROOTS_PAGE, 'courses'), title: 'Đào tạo bồi dưỡng', content: 'khoá học' },
  // polls: { path: path(ROOTS_PAGE, 'polls'), title: 'Dự thảo và biểu quyết', content: 'khảo sát' },
  // HDLSTQ: { path: path(ROOTS_PAGE, 'HDLSTQ'), title: 'Hội đồng luật sư toàn quốc', content: 'HDLSTQ' },
  // TVLD: { path: path(ROOTS_PAGE, 'TVLD'), title: 'Thường vụ liên đoàn', content: 'TVLD' },
  CDLS: { path: path(ROOTS_PAGE, 'CDLS'), title: 'Quản lí luật sư', content: 'CDLS' },

  news: { path: path(ROOTS_PAGE, 'news'), title: 'Tin tức', content: 'tin tức' },
  // forums: { path: path(ROOTS_PAGE, 'forums'), title: 'Diễn đàn', content: 'bài đăng' },
  // agenda: { path: path(ROOTS_PAGE, 'agenda'), title: 'Đại hội agenda', content: 'agenda' },
  fees: { path: path(ROOTS_PAGE, 'fees'), title: 'Kế toán' },
  complains: { path: path(ROOTS_PAGE, 'complains'), title: 'Khiếu nại' },
  protects: { path: path(ROOTS_PAGE, 'protects'), title: 'Bảo vệ quyền lợi của luật sư' },
  // boards: { path: path(ROOTS_PAGE, 'boards'), title: 'Ủy ban khác' },
  // chats: { path: path(ROOTS_PAGE, 'chats'), title: 'Trao đổi' },
}

export { PATH_AUTH, PATH_PAGE }
