import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  provinces: [],
}

const slice = createSlice({
  name: 'CDLS',
  initialState,
  reducers: {
    SET_CDLS: (state, action) => {
      state.provinces = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { SET_CDLS, SET_ALL_ADMIN } = actions

export default reducer
