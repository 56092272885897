import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  comments: [],
  commentsLength: 0,
  commentDelete: null,

  forums: [],
  forumsLength: 0,
  forumDelete: null,
  forumDetail: {},
}

const slice = createSlice({
  name: 'forum',
  initialState,
  reducers: {
    SET_COMMENTS: (state, action) => {
      state.comments = action.payload.comments
      if (action.payload.totalLength) {
        state.commentsLength = action.payload.totalLength
      }
    },
    DELETE_COMMENT: (state, action) => {
      state.commentDelete = action.payload
    },
    SET_FORUMS: (state, action) => {
      state.forums = action.payload.forums
      if (action.payload.totalLength) {
        state.forumsLength = action.payload.totalLength
      }
    },
    SET_FORUM_DETAIL: (state, action) => {
      state.forumDetail = action.payload
    },
    DELETE_FORUM: (state, action) => {
      state.forumDelete = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { SET_FORUMS, SET_FORUM_DETAIL, DELETE_FORUM, SET_COMMENTS, DELETE_COMMENT } = actions

export default reducer
