import React from 'react'
import images from 'constants/images'

import { Box } from '@mui/material'
import { styled } from '@mui/styles'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.sizes.header,
  background: theme.palette.common.white,
  position: 'fixed',
  top: 0,
  zIndex: 10,
  borderRadius: 0,
  width: '100%',
}))

const Header = () => {
  return (
    <Root>
      <Box sx={{ width: theme => theme.sizes.sidebar }}>
        <Box sx={{ width: '80%', m: 'auto' }}>
          <img src={images.logo} alt="logo" />
        </Box>
      </Box>
    </Root>
  )
}

export default Header
