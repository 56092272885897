import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  nqPolls: [],
  mvPolls: [],
  nqLength: 0,
  mvLength: 0,
  tagPoll: 'NQ',
  pollDelete: null,
  pollDetail: {},
}

const slice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    SET_NQ_POLLS: (state, action) => {
      state.nqPolls = action.payload.polls
      if (!action.payload.isAction) {
        state.nqLength = action.payload.totalLength
      }
    },
    SET_MV_POLLS: (state, action) => {
      state.mvPolls = action.payload.polls
      if (!action.payload.isAction) {
        state.mvLength = action.payload.totalLength
      }
    },
    SET_POLL_DETAIL: (state, action) => {
      state.pollDetail = action.payload
    },
    TAG_POLL: (state, action) => {
      state.tagPoll = action.payload
    },
    DELETE_POLL: (state, action) => {
      state.pollDelete = action.payload
    },
  },
})

const { reducer, actions } = slice

export const { SET_NQ_POLLS, SET_MV_POLLS, TAG_POLL, DELETE_POLL, SET_POLL_DETAIL } = actions

export default reducer
