import React from 'react'
import { styled } from '@mui/styles'
import { Slider, Box, Typography, Stack } from '@mui/material'

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 4,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 24,
    height: 24,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}))

const MSlider = props => {
  const { value, onChange, label, children, ...restProps } = props
  return (
    <Box>
      <Typography variant="body2">{label}</Typography>
      <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
        <PrettoSlider
          {...restProps}
          // getAriaLabel={() => 'Tuổi'}
          value={value}
          onChange={onChange}
          // valueLabelDisplay="auto"
          // getAriaValueText={value => `${value} tuổi`}
        />
        {children ? (
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {children}
          </Typography>
        ) : (
          ''
        )}
      </Stack>
    </Box>
  )
}

export default MSlider
