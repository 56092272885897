import React from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

// hooks
import { useAuth } from 'hooks'
import { PATH_AUTH, PATH_PAGE } from 'routes/paths'
// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const requestedLocation = localStorage.getItem('path')

    if (!isAuthenticated) {
      localStorage.setItem('path', pathname || '')
      return navigate(PATH_AUTH.login.path)
    }

    setLoading(false)
    if (requestedLocation && requestedLocation !== pathname) {
      localStorage.removeItem('path')
      return navigate(requestedLocation)
    }

    return navigate('/')
  }, [isAuthenticated])

  if (loading) {
    return <div>loading auth</div>
  }

  return <>{children}</>
}
