import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PATH_PAGE } from 'routes/paths'
import {
  usePoll,
  useForum,
  useNews,
  useProtects,
  useCourse,
  useUser,
  useStyles,
  useAgenda,
  useFee,
  useFilter,
} from 'hooks'
import { fDateOriginal } from 'utils/formatTime'
import htmlToReact from 'utils/htmlToReact'

import { GridActionsCellItem } from '@mui/x-data-grid'
import { Stack, Typography } from '@mui/material'
import { StatusBtn } from 'components/Table'
import icons from 'constants/icons'
import { MAvatar, MButton, MPopover, MActionListItem } from 'components/MUI'

const useColumns = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { lineClampStyle } = useStyles()
  const { handleDeletePoll, handleUpdateStatusPoll } = usePoll()
  const { handleDeleteUser, handleGetUserByID, handleShowUseDetailToEdit, handleDisableLawyer } = useUser()
  const { handleDeleteCourse, handleRemoveCourseMembers, handleUpdateStatusCourse, currentCourse } = useCourse()
  const { handleVerifyPost, handleDeletePost, handleDeleteComment } = useForum()
  const { handleDeleteAgenda } = useAgenda()
  const { handleDeleteNews } = useNews()
  const { handleUpdateFees, handleUpdateFee, handleUpdateComplain } = useFee()
  const { handleUpdateSupportsComplain } = useProtects()
  const { filterTabType } = useFilter()

  const columns = {
    polls: [
      {
        field: 'title',
        headerName: 'Tên khảo sát',
        minWidth: 150,
        maxWidth: 200,
        flex: 0.3,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'description',
        headerName: 'Mô tả',
        minWidth: 150,
        maxWidth: 200,
        flex: 0.4,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'status',
        headerName: 'Trạng thái',
        headerClassName: 'super-app-theme--header',
        minWidth: 230,
        renderCell: params => {
          const { id, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={4}>
              <StatusBtn
                bgcolor={value === 'Open' ? 'success.main' : 'error.main'}
                value={value}
                onClick={() => handleUpdateStatusPoll(id, value)}
              />
              <Typography
                component={Link}
                to={`${location.pathname}/${id}`}
                color="primary"
                variant="body2"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Xem kết quả
              </Typography>
            </Stack>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          // <GridActionsCellItem icon={icons.EditIcon} label="Edit" onClick={() => handleEditPoll(params.id)} />,
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Delete"
            onClick={() => handleDeletePoll(params.id, false)}
          />,
        ],
      },
    ],
    pollsDetail: [
      {
        field: 'title',
        headerName: 'Tên khảo sát',
        minWidth: 150,
        maxWidth: 200,
        flex: 0.3,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'description',
        headerName: 'Mô tả',
        minWidth: 150,
        maxWidth: 200,
        flex: 0.4,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: 'numParticipants',
        headerName: 'Số người tham gia',
        width: 200,
      },

      {
        field: 'status',
        headerName: 'Trạng thái',
        headerClassName: 'super-app-theme--header',
        width: 100,
        renderCell: params => {
          const { id, value } = params
          return (
            <StatusBtn
              bgcolor={value === 'Open' ? 'success.main' : 'error.main'}
              value={value}
              onClick={() => handleUpdateStatusPoll(id, value, true)}
            />
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.EditIcon}
            label="Edit"
            onClick={() => navigate(`${location.pathname}/edit`)}
          />,
        ],
      },
    ],
    statistical: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
        minWidth: 150,
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },

      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        width: 120,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      // {
      //   field: 'CCHN',
      //   headerName: 'Số CCHN',
      //   width: 120,
      //   sortable: false,
      // },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 150,
        sortable: false,
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        width: 120,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      {
        field: 'hours',
        headerName: 'Thời gian',
      },
      {
        field: 'missingHours',
        headerName: 'Số giờ thiếu',
        width: 120,
      },
      // {
      //   field: 'hoan',
      //   headerName: 'Hoãn',
      //   width: 70,
      //   sortable: false,
      //   renderCell: params => {
      //     const { value } = params
      //     return <MCheckbox />
      //   },
      // },
      // {
      //   field: 'duyet',
      //   headerName: 'Duyệt',
      //   width: 70,
      //   sortable: false,

      //   renderCell: params => {
      //     const { value } = params
      //     return <MCheckbox />
      //   },
      // },
      // {
      //   field: 'dat',
      //   headerName: 'Đạt',
      //   width: 70,
      //   sortable: false,

      //   renderCell: params => {
      //     const { value } = params
      //     return <MCheckbox />
      //   },
      // },
      // {
      //   field: 'mien',
      //   headerName: 'Miễn',
      //   width: 70,
      //   sortable: false,

      //   renderCell: params => {
      //     const { value } = params
      //     return <MCheckbox />
      //   },
      // },

      // {
      //   field: 'actions',
      //   headerName: '#',
      //   type: 'actions',
      //   width: 50,
      //   getActions: params => [
      //     <GridActionsCellItem
      //       icon={icons.DeleteIcon}
      //       label="Xóa"
      //       onClick={() => handleDeleteCourse(params.id, false)}
      //     />,
      //   ],
      // },
    ],
    lawyers: [
      {
        field: 'fullname',
        headerName: 'Tên Luật Sư',
        minWidth: 150,
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 260,
        // flex: 1,
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      // {
      //   field: 'CCHN',
      //   headerName: 'Số CCHN',
      //   width: 100,
      // },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 150,
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      {
        field: 'status',
        headerName: 'Trạng thái',
        renderCell: params => {
          const { id, row } = params
          return (
            <StatusBtn
              onClick={() => handleDisableLawyer(id, row.isDisabled ? false : true)}
              value={!row.isDisabled ? 'Enable' : 'Disable'}
              bgcolor={!row.isDisabled ? 'success.main' : 'error.main'}
            />
          )
        },
      },

      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem icons={icons.DeleteIcon} onClick={() => handleDeleteUser(params.id, false)} text="Xóa" />
            </>
          )
          return [<MPopover listItems={ActionListItems} title="Xóa" text={icons.ThreeDotIcon} popoverId="actions" />]
        },
      },
    ],

    lawyersExportExcel: [
      {
        field: 'fullname',
        headerName: 'Họ Tên',
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        type: 'date',
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
      },
      {
        field: 'email',
        headerName: 'Email',
      },

      {
        field: 'CCHN',
        headerName: 'Số CCHN',
        width: 100,
      },

      {
        field: 'phoneNumber',
        headerName: 'Số điện thoại',
      },

      {
        field: 'address',
        headerName: 'Địa chỉ',
      },
    ],
    courses: [
      {
        field: 'title',
        headerName: 'Tên khóa học',
        minWidth: 200,
        flex: 0.5,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },

      // {
      //   field: 'teachers',
      //   headerName: 'Giảng viên',
      //   minWidth: 200,
      //   flex: 0.5,
      //   renderCell: params => {
      //     const { value } = params
      //     return (
      //       <Stack sx={{ flex: 1 }}>
      //         {value.map((item, index) => {
      //           if (index > 1) return
      //           return (
      //             <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(1) }} key={item.id}>
      //               {item.name}
      //               {index > 0 ? <Typography variant="body2">...</Typography> : ''}
      //             </Typography>
      //           )
      //         })}
      //       </Stack>
      //     )
      //   },
      // },

      {
        field: 'time',
        headerName: 'Thời gian',
        type: 'date',
        width: 130,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              {value.length ? fDateOriginal(value[0].date) : ''}
            </Typography>
          )
        },
      },

      {
        field: 'createdAt',
        headerName: 'Tạo ngày',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'status',
        headerName: 'Trạng thái',
        minWidth: 250,
        renderCell: params => {
          const { id, row } = params
          return (
            <Stack direction="row" alignItems="center" spacing={4}>
              <StatusBtn
                onClick={() => handleUpdateStatusCourse(id, Number(row.status) === 0 ? 1 : 0)}
                value={Number(row.status) === 0 ? 'Mở' : 'Đóng'}
                bgcolor={Number(row.status) === 0 ? 'success.main' : 'border.main'}
              />
              <MButton
                onClick={() => navigate(`${PATH_PAGE.courses.path}/${id}`)}
                sx={{ color: 'primary.main', fontWeight: 400 }}
              >
                Quản lý học viên
              </MButton>
            </Stack>
          )
        },
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                icons={icons.DeleteIcon}
                onClick={() => handleDeleteCourse(params.id, false)}
                text="Xóa"
              />
            </>
          )
          return [<MPopover listItems={ActionListItems} title="Xóa" text={icons.ThreeDotIcon} popoverId="actions" />]
        },
      },
    ],
    courseRegisterExportExcel: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
      },
      {
        field: 'email',
        headerName: 'Email',
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      {
        field: 'CCHN',
        headerName: 'Số CCHN',
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 200,
      },
    ],
    courseRegister: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
        width: 150,
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 260,
        // flex: 1,
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      // {
      //   field: 'CCHN',
      //   headerName: 'Số CCHN',
      //   width: 100,
      // },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 150,
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 200,
        disableExport: true,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}>
              {value}
            </Typography>
          )
        },
      },
    ],

    courserUserExportExcel: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
      },

      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      // {
      //   field: 'CCHN',
      //   headerName: 'Số CCHN',
      //   width: 100,
      // },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: 'province',
        headerName: 'Đoàn luật sư',
      },
      {
        field: 'address',
        headerName: 'Địa chỉ nhận chứng chỉ',
      },
      {
        field: 'phonenumber',
        headerName: 'Số điện thoại',
      },
      {
        field: 'email',
        headerName: 'Email',
      },
    ],

    courseUsers: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
        width: 150,
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 260,
        // flex: 1,
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },

      // {
      //   field: 'CCHN',
      //   headerName: 'Số CCHN',
      //   width: 100,
      // },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 150,
      },
      {
        field: 'provideLDate',
        headerName: 'Ngày cấp thẻ',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 200,
        disableExport: true,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}>
              {value}
            </Typography>
          )
        },
      },

      // {
      //   field: 'time',
      //   headerName: 'Thời gian',
      // },
      // {
      //   field: 'missingTime',
      //   headerName: 'Số giờ thiếu',
      //   width: 130,
      // },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                icons={icons.DeleteIcon}
                onClick={() => {
                  handleRemoveCourseMembers(params.id)
                }}
                text="Xóa"
              />
            </>
          )
          return [<MPopover listItems={ActionListItems} title="Xóa" text={icons.ThreeDotIcon} popoverId="actions" />]
        },
      },
    ],

    courseAddUsers: [
      {
        field: 'fullname',
        headerName: 'Tên học viên',
        width: 150,
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'dOB',
        headerName: 'Ngày sinh',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 150,
      },
      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 130,
        disableExport: true,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}>
              {value}
            </Typography>
          )
        },
      },
    ],
    forums: [
      {
        field: 'title',
        headerName: 'Tiêu đề',
        minWidth: 200,
        flex: 0.5,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ whiteSpace: 'pre-wrap', color: 'primary.main', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'createByName',
        headerName: 'Đăng bởi',
        minWidth: 150,
        flex: 0.5,
        renderCell: params => {
          const { row, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <MAvatar src={row?.userImg} alt={value} sx={{ width: 28, height: 28 }} />
              <Typography variant="body2">{value}</Typography>
            </Stack>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Tạo ngày',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: 'verified',
        headerName: 'Phê duyệt',
        minWidth: 230,
        renderCell: params => {
          const { id, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={4}>
              <StatusBtn
                bgcolor={value ? 'success.main' : 'error.main'}
                value={value ? 'Đã duyệt' : 'Chờ duyệt'}
                onClick={() => handleVerifyPost(id, value)}
              />

              <Typography
                component={Link}
                to={`${PATH_PAGE.forums.path}/${id}/comments`}
                color="primary"
                variant="body2"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Quản lý bình luận
              </Typography>
            </Stack>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Delete"
            onClick={() => handleDeletePost(params.id, false)}
          />,
        ],
      },
    ],
    comments: [
      {
        field: 'content',
        headerName: 'Nội dung',
        minWidth: 300,

        flex: 0.5,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'createByName',
        headerName: 'Đăng bởi',
        minWidth: 150,
        flex: 0.5,
        renderCell: params => {
          const { row, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <MAvatar src={row?.userImg} alt={value} sx={{ width: 28, height: 28 }} />
              <Typography>{value}</Typography>
            </Stack>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Tạo ngày',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: 'verified',
        headerName: 'Phê duyệt',
        width: 100,
        renderCell: params => {
          const { id, row, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={4}>
              <StatusBtn
                bgcolor={value ? 'success.main' : 'error.main'}
                value={value ? 'Đã duyệt' : 'Chờ duyệt'}
                onClick={() => handleVerifyPost(id, value, row?.PostId ? true : false)}
              />
            </Stack>
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Delete"
            onClick={() => handleDeleteComment(params.id, false)}
          />,
        ],
      },
    ],
    news: [
      {
        field: 'title',
        headerName: 'Tiêu đề tin tức',
        width: 250,

        renderCell: params => {
          const { row, value } = params
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <MAvatar src={row?.banner_url} alt={value} sx={{ width: 42, height: 42 }} variant="rounded" />
              <Typography
                variant="subtitle2"
                sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(3) }}
              >
                {value}
              </Typography>
            </Stack>
          )
        },
      },
      {
        field: 'html',
        headerName: 'Mô tả tin tức',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="body2"
              sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
              dangerouslySetInnerHTML={{ __html: htmlToReact(value) }}
            />
          )
        },
      },
      // {
      //   field: 'type',
      //   headerName: 'Phân loại',
      //   type: 'date',
      //   width: 150,
      //   valueGetter: ({ value }) => (value === 'LDLS' ? 'Liên đoàn lao sư' : 'Các đoạn luật sư'),
      // },
      {
        field: 'createdAt',
        headerName: 'Ngày đăng',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem icons={icons.DeleteIcon} onClick={() => handleDeleteNews(params.id, false)} text="Xóa" />
            </>
          )
          return [<MPopover listItems={ActionListItems} title="Xóa" text={icons.ThreeDotIcon} popoverId="actions" />]
        },
      },
    ],
    agendas: [
      {
        field: 'title',
        headerName: 'Tên đại hội',
        maxWidth: 250,
        flex: 0.4,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'numDays',
        headerName: 'Thời gian diễn ra',
        width: 130,
        flex: 0.2,
        renderCell: params => {
          const { value } = params
          return <Typography variant="body2">{value} ngày</Typography>
        },
      },

      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Delete"
            onClick={() => handleDeleteAgenda(params.id, false)}
          />,
        ],
      },
    ],
    CDLS: [
      {
        field: 'name',
        headerName: 'Tên tỉnh thành',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography
              variant="subtitle2"
              sx={{ color: 'primary.main', whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}
            >
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'count',
        headerName: 'Số lượng thành viên',
        flex: 1,
      },
    ],
    fees: [
      {
        field: 'fullname',
        headerName: 'Họ tên',
        width: 150,
        flex: 0.5,
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 180,
      },
      {
        field: 'province',
        headerName: 'Tỉnh thành',
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'expertise',
        headerName: 'Chuyên môn',
        width: 150,
      },
      {
        field: 'area',
        headerName: 'Lĩnh vực',
        width: 120,
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: (params, e) => {
          const ActionListItems = (
            <MActionListItem
              icons={icons.BookmarkAddedSharpIcon}
              onClick={e => {
                e.preventDefault()
                handleUpdateFee(params.row, filterTabType === 0 ? false : true)
              }}
              text={`${filterTabType === 1 ? '  Xác nhận' : 'Xóa'}`}
            />
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Xóa"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],
    feesUnpaidOn6Months: [
      {
        field: 'fullname',
        headerName: 'Họ tên',
        width: 120,
        flex: 0.5,
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 180,
      },
      {
        field: 'province',
        headerName: 'Tỉnh thành',
        minWidth: 150,
        flex: 0.5,
      },
      {
        field: 'expertise',
        headerName: 'Chuyên môn',
        width: 150,
      },
      {
        field: 'area',
        headerName: 'Lĩnh vực',
        width: 120,
      },
      {
        field: 'unpaidFees',
        headerName: 'Chưa thanh toán',
        width: 180,
        renderCell: params => {
          const { row } = params
          const listItems = row.unpaidFees.map(fee => (
            <MActionListItem key={fee.id} text={`Tháng ${fee.month} / ${fee.year}`} />
          ))
          return (
            <MPopover
              title="Ấn để xem danh sách các tháng chưa thanh toán"
              text={`${row.unpaidFees.length} tháng`}
              icons={icons.ExpandMoreIcon}
              popoverId={`${row.lawyerCode}`}
              listItems={listItems}
            />
          )
        },
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <MActionListItem
              icons={icons.BookmarkAddedSharpIcon}
              onClick={e => {
                e.preventDefault()
                handleUpdateFees(params.row)
              }}
              text="Xác nhận"
            />
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Xóa"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],
    feeResolvedComplain: [
      {
        field: 'fullname',
        headerName: 'Tên luật sư',
        width: 150,
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 180,
      },
      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 150,
      },
      {
        field: 'content',
        headerName: 'Nội dung khiếu nại',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(3) }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'updatedAt',
        headerName: 'Ngày giải quyết',
        width: 180,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                text="Thay đổi"
                icons={icons.DeleteIcon}
                onClick={e => {
                  e.preventDefault()
                  handleUpdateComplain(params.row.id, false)
                }}
              />
            </>
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Thay đổi"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],
    feeUnresolvedComplain: [
      {
        field: 'fullname',
        headerName: 'Tên luật sư',
        width: 150,
      },

      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 180,
      },
      {
        field: 'province',
        headerName: 'Đoàn luật sư',
        width: 150,
        disableExport: true,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(2) }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'content',
        headerName: 'Nội dung khiếu nại',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(3) }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Ngày khiếu nại',
        width: 180,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                text="Xóa"
                icons={icons.DeleteIcon}
                onClick={e => {
                  e.preventDefault()
                  handleUpdateComplain(params.row.id, true)
                }}
              />
            </>
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Xóa"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],
    meetings: [
      {
        field: 'taskName',
        headerName: 'Tên ...',
        flex: 1,
      },
      {
        field: 'content',
        headerName: 'Chi tiết',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Xóa"
            showInMenu
            onClick={() => handleDeleteUser(params.id, false)}
          />,
        ],
      },
    ],
    plans: [
      {
        field: 'title',
        headerName: 'Tên tài liệu và File',
        flex: 1,
      },
      {
        field: 'createdBy',
        headerName: 'Người đăng',
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: 'Ngày đăng',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },

      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Xóa"
            showInMenu
            onClick={() => handleDeleteUser(params.id, false)}
          />,
        ],
      },
    ],
    insurances: [
      {
        field: 'title',
        headerName: 'Tên bảo hiểm',
        flex: 1,
      },
      {
        field: 'price',
        headerName: 'Mức phí',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return <Typography>{value?.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })}</Typography>
        },
      },
    ],
    supportsResolvedComplain: [
      {
        field: 'fullname',
        headerName: 'Tên người giám sát',
        width: 200,
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 250,
      },
      {
        field: 'content',
        headerName: 'Nội dung khiếu nại',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(3) }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'updatedAt',
        headerName: 'Ngày giải quyết',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                text="Thay đổi"
                icons={icons.DeleteIcon}
                onClick={e => {
                  e.preventDefault()
                  handleUpdateSupportsComplain(params.row.id, false)
                }}
              />
            </>
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Thay đổi"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],
    supportsUnresolvedComplain: [
      {
        field: 'fullname',
        headerName: 'Tên người giám sát',
        width: 200,
      },
      {
        field: 'lawyerCode',
        headerName: 'Số thẻ luật sư',
        width: 250,
      },
      {
        field: 'content',
        headerName: 'Nội dung khiếu nại',
        flex: 1,
        renderCell: params => {
          const { value } = params
          return (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', ...lineClampStyle(3) }}>
              {value}
            </Typography>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'Ngày khiếu nại',
        width: 100,
        valueGetter: ({ value }) => value && fDateOriginal(value),
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 20,
        getActions: params => {
          const ActionListItems = (
            <>
              <MActionListItem
                text="Xóa"
                icons={icons.DeleteIcon}
                onClick={e => {
                  e.preventDefault()
                  handleUpdateSupportsComplain(params.row.id, true)
                }}
              />
            </>
          )
          return [
            <MPopover
              listItems={ActionListItems}
              title="Xác nhận / Xóa"
              text={icons.ThreeDotIcon}
              popoverId="actions"
            />,
          ]
        },
      },
    ],

    documents: [
      {
        field: 'title',
        headerName: 'Tên tài liệu và File',
        flex: 1,
      },
      {
        field: 'createdBy',
        headerName: 'Người đăng',
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: 'Ngày đăng',
        type: 'date',
        width: 130,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: 'actions',
        headerName: 'Công cụ',
        type: 'actions',
        width: 100,
        getActions: params => [
          <GridActionsCellItem
            icon={icons.DeleteIcon}
            label="Xóa"
            showInMenu
            onClick={() => handleDeleteUser(params.id, false)}
          />,
        ],
      },
    ],
  }

  return { columns }
}

export default useColumns
