import { useSelector, useDispatch } from 'react-redux'
import { DELETE_API, GET_API, POST_API, UPDATE_API } from 'routes/api'
import axios from 'utils/axios'
import { useAlert, useAuth, useCDLS } from 'hooks'
import { useLocation } from 'react-router-dom'
import { SET_CDLS } from 'stores/slices/CDLSSlice'

import {
  SET_USERS,
  FILTER_DATE,
  SET_USER_DETAIL,
  TAG_USER,
  DELETE_USER,
  TOGGLE_DIALOG,
  SET_NEW_USER,
  SET_LAWYERS,
  SEARCH_LAWYERS,
  TOGGLE_DISABLE_LAWYER,
} from 'stores/slices/UserSlice'
import { provinces } from 'constants/lawData'

const url = 'http://localhost:3001/lawyers'
const useUser = () => {
  const dispatch = useDispatch()
  const { user, handleLogout } = useAuth()
  const { enqueueSnackbar } = useAlert()
  const { provinces } = useCDLS()

  const location = useLocation()
  // redux
  // const users = useSelector(state => state.user.users) // tag = 0
  const lawyers = useSelector(state => state.user.lawyers) // tag = 1
  // tag = 1
  // const userLength = useSelector(state => state.user.userLength)
  // const lawyerLength = useSelector(state => state.user.lawyerLength)
  const lists = useSelector(state => state.user.lists)
  const listsLength = useSelector(state => state.user.listsLength)
  const tagUser = useSelector(state => state.user.tagUser)

  const userDelete = useSelector(state => state.user.userDelete)
  const userDetail = useSelector(state => state.user.userDetail)
  const isOpenDialog = useSelector(state => state.user.isOpenDialog)
  const filterDate = useSelector(state => state.user.filterDate)
  const searchedLawyers = useSelector(state => state.user.searchedLawyers)
  // handle
  const handleGetTypeUsers = async (tag, page, province, search, mode, current) => {
    try {
      let response
      if (tag === 0) {
        response = await axios.get(
          `${GET_API().lawyersByProvince}/${province.provinceCode}?page=${page}&year=${
            search.year
          }&mode=${mode}&current=${current}`
        )
      } else {
        response = await axios.get(`${GET_API().analysisAdmin}?page=${page}`)
      }
      console.log(response)
      if (response.data.status === 'success') {
        if (mode) {
          return response.data.result
        }
        const newResponse = {
          currentTag: tag,
          totalLength: tag === 0 ? province.count : response.data.totalAdminsLength,
          data: tag === 0 ? response.data.result : response.data.admins,
        }
        dispatch(SET_USERS(newResponse))
      } else {
        enqueueSnackbar(`Vui lòng thử lại sau vài phút `, { variant: 'error' })
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleChangeTag = tag => {
    dispatch(TAG_USER(tag))
  }

  const handleShowUseDetailToEdit = async useDetail => {
    dispatch(TOGGLE_DIALOG(true))
    dispatch(SET_USER_DETAIL(useDetail))
  }

  const handleCreateUser = async form => {
    try {
      const response = await axios.post(POST_API().registerLawyer, form)
      if (response.data.status === 'success') {
        if (location.pathname.includes(form.provinceCode)) {
          dispatch(SET_NEW_USER(response.data.lawyers))
        } else {
          const newProvinces = provinces.map(province => {
            if (province.provinceCode === form.provinceCode) {
              return { ...province, count: province.count + 1 }
            }
            return province
          })
          dispatch(SET_CDLS(newProvinces))
        }
        return true
      }
      enqueueSnackbar(response.data.message, { variant: response.data.status })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleEditProfile = async (form, userId) => {
    try {
      const response = await axios.put(UPDATE_API(userId).adminEditForUser, { email: '', ...form })
      console.log('handleEditProfile', response)
      if (response.data.status === 'success') {
        const newResponse = {
          currentTag: tagUser,
          totalLength: listsLength[tagUser],
          data: lists[tagUser].map(user => {
            if (user.id === userId) {
              return {
                id: userId,
                ...form,
              }
            }
            return user
          }),
        }
        enqueueSnackbar('Thay đổi thông tin thành công', { variant: 'success' })

        dispatch(SET_USER_DETAIL([]))
        dispatch(SET_USERS(newResponse))
        return true
      }
      enqueueSnackbar('Vui lòng thử lại sau ít phút', { variant: 'error' })
      return false
    } catch (error) {
      console.log('err', error)
    }
  }

  // chua xong
  const handleDeleteUser = async (id, isAccept) => {
    if (!isAccept) return dispatch(DELETE_USER(id))
    try {
      const response = await axios.delete(DELETE_API(userDelete).user)
      console.log('handleDeleteUser', response)
      if (response.data.status === 'success') {
        const newResponse = {
          currentTag: tagUser,
          totalLength: listsLength[tagUser] - 1,
          data: lists[tagUser].filter(user => user?.id !== userDelete),
        }
        dispatch(SET_USERS(newResponse))

        enqueueSnackbar('Xóa người dùng thành công', { variant: 'success' })
        dispatch(DELETE_USER(null))
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const handleToggleUserDialog = () => {
    if (isOpenDialog) {
      if (userDetail?._id) dispatch(SET_USER_DETAIL({}))
    }
    dispatch(TOGGLE_DIALOG(!isOpenDialog))
  }

  const handleImportAnalysis = async ({ file, code }) => {
    const formData = new FormData()
    if (tagUser === 0) {
      formData.append('file', file)
    } else {
      // thống kê
      // const getProvince = provinces().filter(item => item.code === code)
      // if (!getProvince.length) return enqueueSnackbar('Link không hợp lệ', { variant: 'error' })
      formData.append('provinceCode', code)
      formData.append('year', filterDate.year)
      formData.append('file', file)
    }

    try {
      const response = await axios.post(POST_API().importLawyers, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data.status === 'success') {
        enqueueSnackbar('Tải file excel thành công', { variant: 'success' })
        const newResponse = {
          totalLength: tagUser === 0 ? response.data.result.length + listsLength[0] : response.data.result.length,
          data:
            tagUser === 0
              ? [...response.data.result.map(({ _id, ...restData }) => ({ id: _id, ...restData })), ...lists[0]]
              : response.data.result.map(item => ({
                  id: Math.floor(Math.random() * 9991293923756),
                  // missingTime: item?.time && Math.floor(16 - parseInt(item?.time, 10)),
                  ...item,
                })),
        }

        return dispatch(SET_USERS(newResponse))
      }
      enqueueSnackbar(response.data.message, { variant: 'error' })
      return false
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleChangeFilterDate = async (e, tag, page, code) => {
    await dispatch(FILTER_DATE({ ...filterDate, [e.target.name]: e.target.value }))
    console.log('tag, page, code', tag, page, code)
    await handleGetTypeUsers(tag, page, code)
  }

  const handleSearchUser = async search => {
    const getMatchSearch = lists[tagUser].filter(item => item?.fullname.toLowerCase().includes(search.toLowerCase()))
    console.log('getMatchSearch', getMatchSearch)
    return getMatchSearch
  }

  const handleSearchLawyers = async (form, page = 1) => {
    try {
      const response = await axios.post(POST_API().searchLawyers, { ...form, page })
      if (response.data.status === 'success') {
        const newResponse = {
          totalLength: response.data.totalLength,
          data: response.data.searchlawyers.map(({ _id, ...restData }) => ({ id: _id, ...restData })),
        }
        return newResponse
      }
      console.log('handleSearchLawyers', response)
    } catch (error) {
      console.log('err', error)
    }
  }
  const handleSearchLawyersOnProvince = async (form, province, page) => {
    try {
      // let response
      // tagUser === 0
      const response = await axios.get(
        `${GET_API().lawyersByProvince}/${province.provinceCode}?page=${page}&searchText=${form.searchText}&year=${
          form.year
        }`
      )
      // ))
      // : (response = await axios.get(
      //     `${GET_API().analysisAdmin}/${province.provinceCode}?page=${page}&searchText=${form.searchText}&year=${
      //       form.year
      //     }`
      //   ))
      // console.log(response)
      if (response.data.status === 'success') {
        dispatch(SEARCH_LAWYERS(response.data.result))
        return
      }
      dispatch(SEARCH_LAWYERS([]))
      enqueueSnackbar(response.data.message, { variant: 'warning' })
    } catch (error) {
      console.log('err', error)
    }
  }

  const resetSearchedLawyersField = () => {
    dispatch(SEARCH_LAWYERS([]))
  }

  const handleDisableLawyer = async (laywerId, isDisabled) => {
    try {
      const response = await axios.patch(UPDATE_API(laywerId).userEdit, { disable: isDisabled })
      if (response.data.status === 'success') {
        dispatch(TOGGLE_DISABLE_LAWYER({ id: laywerId, isDisabled: response.data.result.isDisabled }))
        enqueueSnackbar(
          `${response.data.result.isDisabled ? 'Tạm thời khóa tài khoản luật sư' : 'Tài khoản luật sư đã được mở lại'}`,
          {
            variant: 'success',
          }
        )
        return
      }
      enqueueSnackbar(response.data.message, { variant: response.data.status })
    } catch (error) {
      console.log(error)
    }
  }
  return {
    // users,
    lawyers,
    // userLength,
    // lawyerLength,
    lists,
    listsLength,
    tagUser,
    userDelete,
    userDetail,
    isOpenDialog,
    filterDate,
    searchedLawyers,
    resetSearchedLawyersField,
    handleCreateUser,
    handleDisableLawyer,
    // handleGetUsers,
    handleGetTypeUsers,
    handleChangeTag,
    handleDeleteUser,
    handleShowUseDetailToEdit,
    handleToggleUserDialog,
    handleEditProfile,
    handleImportAnalysis,
    handleChangeFilterDate,
    handleSearchUser,
    handleSearchLawyers,
    handleSearchLawyersOnProvince,
    // handleGetPolls,
    // handleGetPollByID,
    // handleUpdatePoll,
    // handleGetTypePolls,
    // handleCreatePoll,
    // handleEditPoll,
    // handleDeletePoll,
    // handleUpdateStatusPoll,
  }
}

export default useUser
