import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { PaginationCustom, LoadingCustom } from 'components/Table'
import { DataGrid } from '@mui/x-data-grid'

const Container = styled(Box)(({ theme }) => ({
  // height: '63vh',
  width: '100%',
  '& .MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-columnsContainer': {
    background: theme.palette.primary.main,
    '& .MuiDataGrid-iconButtonContainer': {
      '& svg': {
        fill: theme.palette.common.white,
      },
    },
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 400,
      },
      '& .MuiCheckbox-root': {
        color: '#fff',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: theme.palette.common.white,
    },
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-row': {
    '& .MuiDataGrid-cell': {
      fontWeight: 400,
      cursor: 'context-menu',
      // color: theme.palette.border.main,
    },
  },
  '& .MuiDataGrid-iconButtonContainer': {
    '& .MuiBadge-badge': {
      display: 'none',
    },
  },
}))

const DataGridCustom = React.memo(props => {
  const { sx, components } = props
  return (
    <Container sx={sx} title="Ấn để chỉnh sửa thông tin ">
      <DataGrid
        {...props}
        disableColumnMenu
        components={{
          Pagination: PaginationCustom,
          LoadingOverlay: LoadingCustom,
          ...components,
        }}
      />
    </Container>
  )
})

export default DataGridCustom
