import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SET_DATE, SET_SEARCH_TEXT, SET_TAB_TYPE } from 'stores/slices/FilterSlice'

function useFilter() {
  const dispatch = useDispatch()
  const filterDate = useSelector(state => state.filter.date)
  const filterSearchText = useSelector(state => state.filter.searchText)
  const filterTabType = useSelector(state => state.filter.tabType)
  const setDate = date => {
    dispatch(SET_DATE(date))
  }
  const setTabType = tabType => {
    dispatch(SET_TAB_TYPE(tabType))
  }
  const setSearchText = searchText => {
    dispatch(SET_SEARCH_TEXT(searchText))
  }

  return { filterDate, filterSearchText, filterTabType, setTabType, setSearchText, setDate }
}

export default useFilter
