import React from 'react'
import { Stack, Typography } from '@mui/material'
import { MTextField } from 'components/MUI'

const MFormControl = props => {
  const { label, ...inputProps } = props
  return (
    <Stack sx={{ flex: 1 }}>
      <Typography variant="body2">{label}</Typography>
      <MTextField {...inputProps} variant="square" inputStyle={{ height: 15 }} />
    </Stack>
  )
}

export default MFormControl
