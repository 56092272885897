import React from 'react'
import { Stack, Typography } from '@mui/material'

const TitleAndSubTitle = ({ subtitle, title }) => {
  return (
    <Stack alignItems="center">
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'grey.300' }}>
        {subtitle}
      </Typography>
    </Stack>
  )
}

export default TitleAndSubTitle
