import React from 'react'
import { useMediaQuery, Box, MenuItem, SwipeableDrawer, MenuList, Typography, IconButton, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import { Link, useLocation } from 'react-router-dom'

import { sidebarConfig } from 'constants/lawData'
import { MSvgIcon, MTextField } from 'components/MUI'

const Root = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  position: 'fixed',
  transition: '100ms',
}))

const MenuItemStyle = styled(MenuItem)(({ theme, active }) => ({
  height: 70,
  transition: '150ms',
  padding: 0,
  background: active ? theme.palette.common.white : 'transparent',
  '& .MuiButtonBase-root': {
    background: active ? theme.palette.primary.main : 'transparent',
  },

  '& .MuiTypography-root': {
    color: active ? theme.palette.primary.main : theme.palette.common.white,
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.common.white,
  },
  '&:hover': {
    background: theme.palette.common.white,
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },

    '& .MuiButtonBase-root': {
      background: theme.palette.primary.main,
    },
  },
}))

const Sidebar = props => {
  const { open, handleToggleHamburger } = props

  const location = useLocation()
  const { pathname } = location
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  if (matches) {
    return (
      <Box>
        <SwipeableDrawer anchor="left" open={open} onClose={handleToggleHamburger} onOpen={handleToggleHamburger}>
          <Root sx={{ width: theme => theme.sizes.sidebar, height: '100%' }}>
            <MenuList>
              {sidebarConfig.map(item => (
                <MenuItemStyle key={item.title} active={pathname.search(item.path) === 0 ? 'true' : undefined}>
                  <Typography
                    component={Link}
                    to={item.path}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1.5,
                      width: '100%',
                      height: '100%',
                      px: 2,
                      py: 0.5,
                      whiteSpace: ' pre-wrap',
                    }}
                    onClick={handleToggleHamburger}
                  >
                    <IconButton sx={{ '& svg': { fontSize: 18 } }}>
                      <MSvgIcon icon={item.icon} />
                    </IconButton>

                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                      {item.title}
                    </Typography>
                  </Typography>
                </MenuItemStyle>
              ))}
            </MenuList>
          </Root>
        </SwipeableDrawer>
      </Box>
    )
  }

  return (
    <>
      <Root
        sx={{
          width: theme => (open ? theme.sizes.sidebar : 0),
          height: `calc(100% - ${theme.sizes.header}px)`,
          overflow: !open ? 'hidden' : '',
          zIndex: 1,
        }}
      >
        <MenuList>
          {sidebarConfig.map(item => (
            <MenuItemStyle key={item.title} active={pathname.search(item.path) === 0 ? 'true' : undefined}>
              <Typography
                component={Link}
                to={item.path}
                sx={{
                  whiteSpace: ' pre-wrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  width: '100%',
                  height: '100%',
                  px: 2,
                  py: 0.5,
                }}
              >
                <IconButton sx={{ '& svg': { fontSize: 18 } }}>
                  <MSvgIcon icon={item.icon} />
                </IconButton>

                <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                  {item.title}
                </Typography>
              </Typography>
            </MenuItemStyle>
          ))}
        </MenuList>
      </Root>
    </>
  )
}

export default Sidebar
