import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { MButton } from 'components/MUI'

const StatusBtn = props => {
  const { value, onClick, ...restProps } = props
  return (
    <MButton
      bgcolor={'border.main'}
      {...restProps}
      sx={{ px: 1, py: 0.7, fontSize: theme => theme.typography.caption, fontWeight: '700' }}
      onClick={onClick}
    >
      {value}
    </MButton>
  )
}

export default StatusBtn
