import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  complains: {
    data: [],
    total: {
      pages: {},
      complainsOnPage: {},
    },
  },
  searchedComplain: [],
}

const slice = createSlice({
  name: 'protects',
  initialState,
  reducers: {
    // COMPLAINS
    SET_COMPLAINS: (state, action) => {
      console.log(action)
      if (state.complains.total.pages !== action.payload.total.pages) {
        state.complains.total.pages = action.payload.total.pages
      }

      if (state.complains.total.complainsOnPage !== action.payload.total.complainsOnPage) {
        state.complains.total.complainsOnPage = action.payload.total.complainsOnPage
      }
      state.complains.data = action.payload.complains
    },

    DELETE_COMPLAIN: (state, action) => {
      // find index of the complain that going to delete
      const complainIndex = state.complains.data[action.payload.tabType].findIndex(
        complain => complain.id === action.payload.id
      )
      state.complains.data[action.payload.tabType].splice(complainIndex, 1)
    },

    ACCEPT_COMPLAIN: (state, action) => {
      const { id, tabType } = action.payload
      // delete Complain trong unresolved complains
      // tim ra unresolvedComplain index
      const acceptedComplainIndex = state.complains.data[tabType].findIndex(complain => complain.id === id)
      // Xóa fee được xác nhận khỏi array unpaidcomplains hoac nguoc lai

      if (tabType === 0) {
        // tim complain da thay doi
        const acceptedComplain = state.complains.data[0].find(complain => complain.id === id)
        acceptedComplain.isResolved = false
        state.complains.data[0].splice(acceptedComplainIndex, 1)

        // chuyen sang phan don khiue nai chua xu li
        state.complains.data[1].unshift(acceptedComplain)
      } else {
        // tim complain da thay doi
        const acceptedComplain = state.complains.data[1].find(complain => complain.id === id)
        acceptedComplain.isResolved = true
        state.complains.data[1].splice(acceptedComplainIndex, 1)
        // chuyen sang phan don khiue nai chua xu li
        state.complains.data[0].unshift(acceptedComplain)
      }
    },

    SET_SEARCHED_COMPLAIN: (state, action) => {
      state.searchedComplain = action.payload
    },
    RESET_SEARCHED_COMPLAIN: state => {
      state.searchedComplain.splice(0, state.searchedComplain.length)
    },
  },
})

const { reducer, actions } = slice

export const { SET_COMPLAINS, ACCEPT_COMPLAIN, DELETE_COMPLAIN, SET_SEARCHED_COMPLAIN, RESET_SEARCHED_COMPLAIN } =
  actions

export default reducer
