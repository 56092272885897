import React from 'react'
import images from 'constants/images'
import icons from 'constants/icons'
import { useAuth, useStyles } from 'hooks'
import { Box, useMediaQuery, InputAdornment, Button, Paper, Stack, IconButton, Badge, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import { MenuAuth } from 'components/authentication'
import { MSvgIcon, MAvatar, MTextField, MButton } from 'components/MUI'

const Root = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: theme.sizes.header,
  background: theme.palette.common.white,
  position: 'fixed',
  top: 0,
  zIndex: 10,
  borderRadius: 0,
  width: '100%',
}))

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.main}`,
}))

const BadgeStyle = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-dot': {
    top: 5,
    right: 5,
  },
}))

const BadgeField = props => {
  const { children, open = false } = props
  return (
    <BadgeStyle {...props} color="primary" variant="dot" invisible={!open}>
      {children}
    </BadgeStyle>
  )
}

const IconButtonField = props => {
  const { icon } = props
  return (
    <IconButtonStyle {...props}>
      <MSvgIcon icon={icon} fontSize="small" sx={{ color: 'border.main' }} />
    </IconButtonStyle>
  )
}

const Header = props => {
  const { handleToggleHamburger, open } = props
  const { user, handleLogout } = useAuth()
  const { lineClampStyle } = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const anchorMenuRef = React.useRef()
  const [openMenu, setOpenMenu] = React.useState(false)

  const handleToggleMenu = () => {
    console.log('!openMenu', !openMenu)
    setOpenMenu(!openMenu)
  }

  return (
    <Root elevation={7}>
      <MenuAuth anchor={anchorMenuRef} open={openMenu} onClose={handleToggleMenu} handleLogout={handleLogout} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: theme => theme.sizes.sidebar, pl: matchesMobile ? 3 : 0 }}
      >
        {!matchesMobile ? (
          <Box sx={{ width: !matchesMobile ? '80%' : '60%', pl: 3 }}>
            <img src={images.logo} alt="logo" />
          </Box>
        ) : (
          ''
        )}
        <Box
          sx={{
            // position: 'fixed',
            // left: theme => (open ? theme.sizes.sidebar : 0),
            // zIndex: 2,
            // top: '50%',
            // left: 0,
            // width: theme => (open ? theme.sizes.sidebar : 30) 30,
            // height: `calc(100% - ${theme.sizes.header}px)`,
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
            transform: 'all 1s ease',
          }}
        >
          <IconButton
            onClick={handleToggleHamburger}
            sx={{
              '& svg': { fill: theme => theme.palette.primary.main },
              borderRadius: 0,
              '&:hover': {
                bgcolor: 'transparent',
              },
            }}
          >
            {open ? icons.MenuOpenIcon : icons.MenuIcon}
          </IconButton>
        </Box>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ pr: 3, pl: { xs: 0, md: 3 }, flexGrow: 1, justifyContent: 'flex-end' }}
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* {!matches ? (
            <>
              <BadgeField open={true}>
                <IconButtonField icon={icons.MessageIcon} />
              </BadgeField>
              <BadgeField>
                <IconButtonField icon={icons.NotificationIcon} />
              </BadgeField>
              <IconButtonField icon={icons.SettingIcon} />
            </>
          ) : (
            ''
          )} */}

          <Stack direction="row" alignItems="center" spacing={1} sx={{ maxWidth: 200, zIndex: 2 }}>
            <Button
              ref={anchorMenuRef}
              onClick={handleToggleMenu}
              sx={{ textTransform: 'capitalize', '&:hover': { backgroundColor: 'transparent' } }}
            >
              <MAvatar alt={user?.fullname} src={user?.image_url} />
              <Stack sx={{ ml: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: 'common.black',
                    ...lineClampStyle(1),
                  }}
                >
                  {user?.fullname}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'primary',
                    ...lineClampStyle(1),
                  }}
                >
                  {user?.role}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Root>
  )
}

export default Header
