import * as React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

export default function SwitchCustom(props) {
  const { label = '', labelPlacement = 'end', ...rest } = props
  return (
    <FormGroup
      sx={{
        '& .MuiFormControlLabel-root': {
          m: 0,
        },
      }}
    >
      <FormControlLabel labelPlacement={labelPlacement} control={<Switch {...rest} size="small" />} label={label} />
    </FormGroup>
  )
}
